import { observer } from "mobx-react-lite";
import { useStore } from "@/store";
import { PenSquare, MoreVertical, Trash2 } from "lucide-react";
import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import { clsxMerge } from "@/editor/utils";
import { Popover, Popconfirm, message } from "antd";

function MiddleNote() {
  const { noteStore } = useStore();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    noteStore.editFirstNote();
  }, []);

  const CreateNote = useDebouncedCallback(() => {
    noteStore.createNote().then((r) => {
      if (r.code !== 0) {
        messageApi.error(r.msg);
      }
    });
  }, 1000);
  const editNote = (e) => {
    noteStore.editActionInfo = e;
    noteStore.pairEditNote(e.Id);
  };

  const delNote = async (e) => {
    await noteStore.delNote(e).then((res) => {
      if (res.code !== 0) {
        messageApi.error(res.msg);
      }
    });
  };
  return (
    <>
      {contextHolder}
      <div className="float-left h-[100vh] w-[25%] min-w-[350px] overflow-y-scroll border-r border-slate-300 ">
        <div className="sticky top-0 flex h-12 items-center bg-white px-6 pb-7 pt-10">
          <div className="flex cursor-pointer items-center" onClick={CreateNote}>
            <PenSquare size={18} />
            <span className="pl-1"> 新建笔记</span>
          </div>
        </div>
        <div className="mt-1 px-6">
          <ul>
            {noteStore.allData.map((item, index) => {
              return (
                <li
                  key={index}
                  className={clsxMerge("group relative cursor-pointer border-b border-slate-200 p-4 hover:bg-gray-100", {
                    "bg-gray-200": item.Id === noteStore.editActionInfo.Id
                  })}
                  onClick={() => editNote(item)}
                >
                  <div className="flex items-center justify-between">
                    {item.Name.length > 0 ? (
                      <span className="max-w-[230px] truncate text-lg font-medium">{item.Name}</span>
                    ) : (
                      <span className="max-w-[230px] truncate text-lg text-gray-500">Untitled</span>
                    )}

                    <span className="ml-1 min-w-[60px] whitespace-nowrap text-right text-xs text-gray-500">
                      {dayjs.unix(item.UpdateTime).format("M月DD日 HH:mm")}
                    </span>
                  </div>
                  {item.Description.length > 0 ? <div className="w-auto truncate text-sm text-gray-500">{item.Description}</div> : ""}
                  <Popover
                    content={
                      <div>
                        <Popconfirm
                          title="删除"
                          description="您确认要删除吗?"
                          okText="删除"
                          cancelText="取消"
                          okButtonProps={{
                            className: "bg-emerald-500"
                          }}
                          onConfirm={() => {
                            delNote(item);
                          }}
                        >
                          <div className="flex cursor-pointer items-center justify-center px-2">
                            <Trash2 color="#766f6f" /> <p className="pl-1">删除</p>
                          </div>
                        </Popconfirm>
                      </div>
                    }
                    // trigger="click"
                    placement="bottomRight"
                  >
                    <div className="absolute right-[-20px] top-1/3 hidden  group-hover:block">
                      <MoreVertical color="#766f6f" size={20} />
                    </div>
                  </Popover>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default observer(MiddleNote);
