import { Check, ChevronDown } from "lucide-react";
import * as Popover from "@radix-ui/react-popover";

const TEXT_COLORS = [
  {
    name: "Default",
    color: "var(--black)"
  },
  {
    name: "Purple",
    color: "#9333EA"
  },
  {
    name: "Red",
    color: "#E00000"
  },
  {
    name: "Yellow",
    color: "#EAB308"
  },
  {
    name: "Blue",
    color: "#2563EB"
  },
  {
    name: "Green",
    color: "#008A00"
  },
  {
    name: "Orange",
    color: "#FFA500"
  },
  {
    name: "Pink",
    color: "#BA4081"
  },
  {
    name: "Gray",
    color: "#A8A29E"
  }
];

const HIGHLIGHT_COLORS = [
  {
    name: "Default",
    color: "#ffffff"
  },
  {
    name: "Purple",
    color: "#f6f3f8"
  },
  {
    name: "Red",
    color: "#fdebeb"
  },
  {
    name: "Yellow",
    color: "#fbf4a2"
  },
  {
    name: "Blue",
    color: "#c1ecf9"
  },
  {
    name: "Green",
    color: "#acf79f"
  },
  {
    name: "Orange",
    color: "#faebdd"
  },
  {
    name: "Pink",
    color: "#faf1f5"
  },
  {
    name: "Gray",
    color: "#f1f1ef"
  }
];

export const ColorModel = ({ editor, isOpen, setIsOpen }) => {
  const activeColorItem = TEXT_COLORS.find(({ color }) => editor.isActive("textStyle", { color }));

  const activeHighlightItem = HIGHLIGHT_COLORS.find(({ color }) => editor.isActive("highlight", { color }));

  return (
    <Popover.Root open={isOpen}>
      <div className="relative h-full">
        <Popover.Trigger
          className="flex h-full items-center gap-1 p-2 text-sm font-medium text-stone-600 hover:bg-stone-100 active:bg-stone-200"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span
            className="rounded-sm px-1 border border-stone-200"
            style={{
              color: activeColorItem?.color,
              backgroundColor: activeHighlightItem?.color
            }}
          >
            A
          </span>

          <ChevronDown className="h-4 w-4" />
        </Popover.Trigger>

        <Popover.Content
          align="start"
          className="z-50 flex max-h-80 w-40 flex-col overflow-hidden overflow-y-auto rounded border border-stone-200 bg-white my-1 p-1 shadow-xl"
        >
          <div className="my-1 px-2 text-sm text-stone-500">Color</div>
          {TEXT_COLORS.map(({ name, color }, index) => (
            <button
              key={index}
              onClick={() => {
                editor.commands.unsetColor();
                editor.commands.unsetHighlight();
                name !== "Default" &&
                  editor
                    .chain()
                    .focus()
                    .setColor(color || "")
                    .run();
                setIsOpen(false);
              }}
              className="flex items-center justify-between rounded-sm px-2 py-1 text-sm text-stone-600 hover:bg-stone-100"
              type="button"
            >
              <div className="flex items-center space-x-2">
                <div className="rounded-sm border border-stone-200 px-1 py-px font-medium" style={{ color }}>
                  A
                </div>
                <span>{name}</span>
              </div>
              {editor.isActive("textStyle", { color }) && <Check className="h-4 w-4" />}
            </button>
          ))}

          <div className="mb-1 mt-2 px-2 text-sm text-stone-500">Background</div>

          {HIGHLIGHT_COLORS.map(({ name, color }, index) => (
            <button
              key={index}
              onClick={() => {
                editor.commands.unsetColor();
                editor.commands.unsetHighlight();
                name !== "Default" && editor.commands.setHighlight({ color });
                setIsOpen(false);
              }}
              className="flex items-center justify-between rounded-sm px-2 py-1 text-sm text-stone-600 hover:bg-stone-100"
              type="button"
            >
              <div className="flex items-center space-x-2">
                <div className="rounded-sm border border-stone-200 px-1 py-px font-medium" style={{ backgroundColor: color }}>
                  A
                </div>
                <span>{name}</span>
              </div>
              {editor.isActive("highlight", { color }) && <Check className="h-4 w-4" />}
            </button>
          ))}
        </Popover.Content>
      </div>
    </Popover.Root>
  );
};
