import { Extension } from "@tiptap/core";
import Suggestion from "@tiptap/suggestion";
import renderItems from "./renderItems";
import getSuggestionItems from "./items";

const Command = Extension.create({
  name: "slashCommands",

  addOptions: {
    suggestion: {
      char: "/",
      startOfLine: false,
      command: ({ editor, range, props }) => {
        props.command({ editor, range, props });
      }
    }
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion
      })
    ];
  }
});
const slashCommands = Command.configure({
  suggestion: {
    items: getSuggestionItems,
    render: renderItems
  }
});
export default slashCommands;
