import { Button, Form, Input, message, Modal, Spin } from "antd";
import { LockKeyhole, User2 } from "lucide-react";

import { useStore } from "@/store";
import { getStorage, removeStorage } from "@/utils";
import background from "@images/login/background.png";
import logo from "@images/login/logo.png";
import { clearAll } from "@utils/db";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const { loginStore, mainStore } = useStore();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tip, setTip] = useState("登录中...");
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    mainStore.clearIntervalAll();
    const userinfo = getStorage("userinfo");
    if (userinfo && userinfo["token"] !== undefined) {
      navigate("/", { replace: true });
    } else {
      clearAll();
    }
  }, []);

  const onFinish = async (values) => {
    const { userCount, password } = values;
    setIsModalOpen(true);
    await loginStore.login({ userCount, password }).then((res) => {
      if (res.code === 0) {
        messageApi.success({
          content: "登录成功",
          duration: 2,
          style: {
            marginTop: "20%"
          },
          onClose: () => {
            setTip("数据初始化ing...");
            getData();
          }
        });
      } else {
        setIsModalOpen(false);
        messageApi.error({
          content: res.msg,
          duration: 3,
          style: {
            marginTop: "20%"
          }
        });
      }
    });
  };
  const getData = async () => {
    await mainStore.getData(true).then((res) => {
      if (res.code !== 0) {
        setIsModalOpen(false);
        removeStorage("userinfo");
        loginStore.userinfo = null;
        messageApi.error({
          content: "登录后个人数据获取失败,请稍候重试",
          duration: 3,
          style: {
            marginTop: "20%"
          },
          onClose: () => {
            window.location.reload();
          }
        });
      } else {
        navigate("/", { replace: true });
      }
    });
  };

  return (
    <>
      {" "}
      {contextHolder}
      <div className="flex h-full w-auto flex-row">
        <div className="h-full w-1/2 bg-[#fafafc]">
          <div className="flex h-full flex-col justify-between">
            <div className="relative left-[15%] top-[10%]">
              <div>
                <img src={logo} alt="logo" className="h-12 w-12" />
              </div>
              <div className="pl-1 pt-2 ">
                <p className="text-gray-400">耕耘忠实遵循GTD时间管理体系</p>
                <p className="text-gray-400">帮助您自信地管理和完成日程安排、任务和笔记</p>
              </div>
            </div>
            <div className="flex justify-center">
              <img src={background} alt="background" className="h-[80%]" />
            </div>
          </div>
        </div>
        <div className="h-full w-1/2">
          <div className="h-full">
            <div className="relative top-[20%] mx-20">
              <div className="text-4xl font-medium">现在登录到耕耘</div>
              <div className="mt-2 text-sm text-gray-400">
                没有账号？免费
                <Link to={"/register"} className="text-sky-500">
                  注册
                </Link>
              </div>
            </div>
            <div className="relative top-[40%] mx-20 w-[50%]">
              <Form name="normal_login" autoComplete="on" onFinish={onFinish}>
                <Form.Item
                  name="userCount"
                  rules={[
                    {
                      required: true,
                      message: "请填写手机号或者账号"
                    }
                  ]}
                >
                  <Input prefix={<User2 size={18} className="text-stone-500" />} size={"large"} placeholder="手机号或者账号" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "请填写密码"
                    }
                  ]}
                >
                  <Input.Password prefix={<LockKeyhole size={18} className="text-stone-500" />} size={"large"} type="password" placeholder="密码" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" className="float-right w-28 bg-emerald-500">
                    登录
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <Modal
          open={isModalOpen}
          centered={true}
          footer={null}
          width={200}
          closable={false}
          classNames={{
            body: "h-24"
          }}
        >
          <Spin tip={tip} size="large" className="mt-12">
            <div className="content" />
          </Spin>
        </Modal>
      </div>
    </>
  );
}

export default Login;
