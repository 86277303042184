const randomNum = (length) => {
  length || (length = 6);

  let num="";
  for(let i=0;i<length;i++)
  {
    num += Math.floor(Math.random()*10);
  }
  return parseInt(num)
}

const randomStr = (length) => {
  length || (length = 8);
  const numbers = '0123456789';
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let total = '';
  total = numbers + letters;
  let result = '';

  while (length > 0) {
    length--;
    result += total[Math.floor(Math.random() * total.length)];
  }
  return result;
}

export {randomNum, randomStr}