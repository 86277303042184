import { Button, Divider, Form, Input, message, Modal, Popconfirm, Popover, Radio, Select, Space, Tree } from "antd";
import "@css/mainLeftClass.scss";
import { reaction, runInAction } from "mobx";
import { getOneFolder, getOneProject } from "@utils/db";
import { activityIcon, descriptionIcon, fileIcon, findColor } from "@utils/icon";
import { AlignJustify, Plus } from "lucide-react";

import { useStore } from "@/store";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { observer } from "mobx-react-lite";

const MainLeftClass = observer(function () {
  const { projectStore, folderStore, actionStore, contextStore } = useStore();
  const [messageApi, contextHolder] = message.useMessage();
  const [formProject] = Form.useForm();
  const [formFolder] = Form.useForm();
  const [treeData, setTreeData] = useState([]);
  const [folderTitle, setFolderTitle] = useState("");

  useEffect(() => {
    reaction(
      () => projectStore.treeData,
      () => {
        setTreeData(handleTree(projectStore.treeData));
      },
      { fireImmediately: true }
    );
  }, []);

  const handleTree = (arr) => {
    return arr.map((item) => {
      let json = { ...item };
      json.title = handleTreeTitle(item);
      json.children = item.children ? handleTree(item.children) : [];
      return json;
    });
  };

  const handleTreeTitle = (item) => {
    const content = (
      <div>
        <p
          className="sandyx-menu"
          onClick={() => {
            item.type === 2 ? openEditProject(item) : openEditFolder(item);
          }}
        >
          编辑
        </p>
        <Popconfirm
          title="删除"
          description="您确认要删除吗?"
          okText="删除"
          cancelText="取消"
          okButtonProps={{
            className: "bg-emerald-500"
          }}
          onConfirm={() => {
            item.type === 2 ? delProject(item) : delFolder(item);
          }}
        >
          <p className="sandyx-menu">删除</p>
        </Popconfirm>
      </div>
    );
    return (
      <>
        <span>{item.title}</span>
        <span className="zc-qb-gd r">
          <Popover content={content} trigger="hover" placement="rightTop">
            <span className="zc-qb-gd-tu"></span>
          </Popover>
        </span>
      </>
    );
  };

  const onClick = (selectedKeys, info) => {
    runInAction(() => {
      actionStore.editId = null;
      actionStore.editData = null;
      actionStore.groupType = "project";
      actionStore.groupOtherId = info.key;
      actionStore.tableTitle = info.title;
    });
  };

  const onDragEnter = (e) => {
    console.log("拖拽");
  };

  const openAddProject = () => {
    projectStore.defaultArr();
    projectStore.switchModel();
    projectDefault();
  };

  const projectDefault = () => {
    formProject.setFieldsValue({
      Id: projectStore.arr.Id,
      Name: projectStore.arr.Name,
      Type: !projectStore.arr.Type ? 0 : parseInt(projectStore.arr.Type),
      Status: projectStore.arr.Status === 0 || projectStore.arr.Status === 2 ? 0 : 3,
      SeqType: projectStore.arr.SeqType,
      FolderId: projectStore.arr.FolderId !== 0 ? projectStore.arr.FolderId : null
    });
  };

  const openEditProject = async (item) => {
    await projectStore.getOptions(item);
    await getOneProject(item.key).then((res) => {
      runInAction(() => {
        let one = { ...res };
        if (res.FolderId !== 0) {
          one.FolderId = res.FolderId + "-1";
        }
        if (res.FId !== 0) {
          one.FolderId = res.FId + "-2";
        }
        projectStore.arr = one;
        projectDefault();
        projectStore.switchModel();
      });
    });
  };

  const delProject = async (item) => {
    await projectStore.delProject(item.key);
  };

  const formProjectSubmit = async (values) => {
    projectStore.switchModel();
    projectStore.defaultArr();
    await projectStore.saveProject(values).then((res) => {
      if (res.code !== 0) {
        messageApi.open({ type: "error", content: res.msg });
      }
    });
  };

  const modelProjectCancel = () => {
    projectStore.showModel = false;
    projectStore.defaultArr();
    formProject.resetFields();
  };

  const openAddFolder = () => {
    modelProjectCancel();
    setFolderTitle("新增文件夹");
    folderStore.defaultArr();
    folderStore.switchModel();
  };

  const openEditFolder = async (item) => {
    modelProjectCancel();
    setFolderTitle("编辑");
    await getOneFolder(item.key).then((res) => {
      formFolder.setFieldsValue({
        Id: res.Id,
        Name: res.Name
      });
      folderStore.switchModel();
    });
  };

  const formFolderSubmit = async (values) => {
    folderStore.switchModel();
    folderStore.defaultArr();
    await folderStore.saveFolder(values).then((res) => {
      if (res.code !== 0) {
        messageApi.open({ type: "error", content: res.msg });
      }
    });
  };

  const delFolder = async (item) => {
    await folderStore.delFolder(item.key).then();
  };

  const modelFolderCancel = () => {
    folderStore.showModel = false;
    folderStore.defaultArr();
    formFolder.resetFields();
  };

  const options = findColor(null, 23);

  return (
    <>
      {contextHolder}
      <Tree
        blockNode
        showIcon
        // draggable
        switcherIcon={() => null}
        selectable={false}
        // onSelect={onSelect}
        onClick={onClick}
        treeData={treeData}
        onDragEnter={onDragEnter}
        onDragEnd={onDragEnter}
      />

      <div className="xj-fz">
        <img alt="+" src={require("../assets/images/xj-fz.jpg")} />
        <p className="zc-text l" onClick={openAddProject}>
          新增分组
        </p>
      </div>
      <Modal open={projectStore.showModel} centered={true} onCancel={modelProjectCancel} footer={null} destroyOnClose={true} forceRender>
        <Form size="large" layout="horizontal" style={{ marginTop: "25px" }} labelCol={{ span: 2 }} onFinish={formProjectSubmit} form={formProject}>
          <Form.Item name="Id" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="Name"
            rules={[
              {
                required: true,
                message: "请填写分组名称!"
              }
            ]}
          >
            <Input placeholder="分组名称" />
          </Form.Item>
          <Form.Item name="Type">
            <Radio.Group buttonStyle="outline" name="Type" size="small">
              {options.map((item) => (
                <Radio.Button key={uuid()} value={item.value}>
                  {item.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item colon={false} name="FolderId" label={fileIcon(20)}>
            <Select
              style={{ width: 300 }}
              placeholder="所属文件夹/分组"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button type="text" icon={<Plus size={20} />} onClick={openAddFolder} className="flex items-center">
                      新建文件夹
                    </Button>
                  </Space>
                </>
              )}
              options={projectStore.options}
            />
          </Form.Item>
          <Form.Item colon={false} name="SeqType" label={<AlignJustify size={20} />}>
            <Select
              style={{
                width: 120
              }}
              options={projectStore.seqTypeOptions}
            />
          </Form.Item>
          <Form.Item colon={false} name="Status" label={activityIcon()}>
            <Select
              style={{
                width: 120
              }}
              options={projectStore.statusOptions}
            />
          </Form.Item>

          <Form.Item colon={false} name="Description" label={descriptionIcon()}>
            <Input placeholder="添加描述" />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button onClick={modelProjectCancel}>取消</Button>
              <Button type="primary" htmlType="submit" className="bg-emerald-500">
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      <Modal title={folderTitle} open={folderStore.showModel} centered={true} onCancel={modelFolderCancel} footer={null} forceRender>
        <Form size="large" layout="horizontal" style={{ marginTop: "25px" }} labelCol={{ span: 2 }} onFinish={formFolderSubmit} form={formFolder}>
          <Form.Item name="Id" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="Name"
            rules={[
              {
                required: true,
                message: "请填写文件夹名称!"
              }
            ]}
          >
            <Input placeholder="文件夹名称" />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button onClick={modelFolderCancel}>取消</Button>
              <Button type="primary" htmlType="submit" className="bg-emerald-500">
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});
export { MainLeftClass };
