import { observer } from "mobx-react-lite";
import { Button, Form, message, Modal, Popconfirm, Popover, Select, Space, Dropdown } from "antd";
import { useStore } from "@/store";
import { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { reaction, runInAction } from "mobx";
import { markIcon, listIcon, delIcon } from "@utils/icon";
import dayjs from "dayjs";
import { MiddleQuick } from "@components/middleQuick";
import { delAction as delAction0 } from "@utils/db";

const Middle = observer(function () {
  const { actionStore, actionSubStore, contextStore, projectStore, noteStore } = useStore();
  const [contextModelOpen, setContextModelOpen] = useState(false);
  const [projectModelOpen, setProjectModelOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [formChangeContext] = Form.useForm();
  const [formChangeProject] = Form.useForm();

  useEffect(() => {
    delAction0(0);
    updateActionList();
    reaction(
      () => actionStore.allData,
      () => {
        runInAction(() => {
          updateActionList();
        });
      },
      { fireImmediately: true }
    );
    reaction(
      () => actionStore.groupType,
      () => {
        runInAction(() => {
          updateActionList();
        });
      }
    );
    reaction(
      () => actionStore.groupOtherId,
      () => {
        runInAction(() => {
          updateActionList();
        });
      }
    );
  }, []);

  const updateActionList = () => {
    actionStore.getList();
  };

  const delAction = async (item) => {
    await actionStore.doDel(item).then((res) => {
      if (res.code === 0) {
        messageApi.open({ type: "success", content: "行动已删除" });
        updateActionList();
      } else {
        messageApi.open({ type: "error", content: res.msg });
      }
    });
  };
  const doComplete = async (item) => {
    await actionStore.doComplete(item).then((res) => {
      if (res.code === 0) {
        messageApi.open({ type: "success", content: "一项行动已完成" });
        updateActionList();
      } else {
        messageApi.open({ type: "error", content: res.msg });
      }
    });
  };

  const doRepeal = async (item) => {
    let params = { ...item };
    params.Status = 0;
    params.CompleteTime = 0;
    await actionStore.saveAction(params).then((res) => {
      if (res.code === 0) {
        updateActionList();
      } else {
        messageApi.open({ type: "error", content: res.msg });
      }
    });
  };
  const doFlag = async (item) => {
    let params = { ...item };
    params.Flag = item.Flag === 0 ? 1 : 0;
    await actionStore.saveAction(params).then((res) => {
      if (res.code === 0) {
        updateActionList();
      } else {
        messageApi.open({ type: "error", content: res.msg });
      }
    });
  };
  const modelContextCancel = () => {
    setContextModelOpen(false);
    formChangeContext.resetFields();
  };
  const modelContextOpen = (item) => {
    if (item.ContextId && item.ContextId !== "0") {
      formChangeContext.setFieldsValue({
        ContextId: item.ContextId.split(",")
      });
    }
    actionStore.editId = item.Id;
    actionStore.editData = item;
    setContextModelOpen(!contextModelOpen);
  };
  const editActionContext = async (item) => {
    let params = { ...actionStore.editData };
    params.ContextId = item.ContextId.length > 0 ? item.ContextId.join(",") : "0";
    await actionStore.saveAction(params).then((res) => {
      if (res.code === 0) {
        setContextModelOpen(false);
        formChangeContext.resetFields();
        messageApi.open({ type: "success", content: "行动便签修改成功", duration: 2 });
      } else {
        messageApi.open({ type: "error", content: res.msg });
      }
    });
  };
  const modelProjectCancel = () => {
    setProjectModelOpen(false);
    formChangeProject.resetFields();
  };
  const modelProjectOpen = (item) => {
    if (item.ProjectId !== 0) {
      formChangeProject.setFieldsValue({
        ProjectId: item.ProjectId
      });
    }
    runInAction(() => {
      actionStore.editId = item.Id;
      actionStore.editData = item;
    });
    setProjectModelOpen(!contextModelOpen);
  };
  const editActionProject = async (item) => {
    let params = { ...actionStore.editData };
    if (item.ProjectId === -1) {
      params.Status = 1;
    } else {
      params.ProjectId = item.ProjectId ? item.ProjectId : 0;
    }
    await actionStore.saveAction(params).then((res) => {
      if (res.code === 0) {
        setProjectModelOpen(false);
        formChangeProject.resetFields();
        messageApi.open({
          type: "success",
          content: "行动分组修改成功",
          duration: 2
        });
      } else {
        messageApi.open({
          type: "error",
          content: res.msg
        });
      }
    });
  };

  const changeRight = (item) => {
    if (item.Id === 0) {
      return;
    }
    runInAction(() => {
      actionStore.editData = item;
      actionStore.editId = item.Id;
    });
  };

  const listSwitch = (e) => {
    if (e.currentTarget.className === "zhuan") {
      e.currentTarget.className = "";
      e.target.nextElementSibling.style.display = "none";
    } else {
      e.currentTarget.className = "zhuan";
      e.target.nextElementSibling.style.display = "";
    }
  };
  const changeAllType = ({ item, key }) => {
    runInAction(() => {
      actionStore.editId = null;
      actionStore.editData = null;
      actionStore.groupType = "all";
      actionStore.allType = key;
    });
    updateActionList();
  };
  return (
    <>
      {contextHolder}
      <div className="all l">
        <div className="zj-gdt">
          <p
            className="all-top"
            onClick={() => {
              actionStore.quickAddShowSwitch(false);
            }}
          >
            {actionStore.tableTitle}
            <Dropdown
              trigger={["click"]}
              menu={{ items: actionStore.allTypeOptions, onClick: changeAllType, selectedKeys: [actionStore.allType] }}
              placement="bottom"
              arrow={{
                pointAtCenter: true
              }}
            >
              <img className="sj-px" src={require("../assets/images/baseline_swap_vert_black_28dp.png")} alt="" />
            </Dropdown>
          </p>
          <MiddleQuick />
        </div>
        <div
          onClick={() => {
            actionStore.quickAddShowSwitch(false);
          }}
          className="collect2 content mCustomScrollbar"
        >
          <ul className="collect">
            {actionStore.group.map((item) => (
              <li key={uuid()}>
                {item.title && item.list.length > 0 ? (
                  <p className="zhuan" onClick={listSwitch}>
                    <img src={require("../assets/images/img12.jpg")} alt="" />
                    {item.title}
                  </p>
                ) : (
                  ""
                )}
                <ul className="jl-text">
                  {item.list.map((li) => (
                    <li
                      key={uuid()}
                      onClick={() => {
                        changeRight(li);
                      }}
                    >
                      {li.Status !== 9 ? (
                        <span
                          className="sp-kk"
                          onClick={() => {
                            doComplete(li);
                          }}
                        ></span>
                      ) : (
                        <span
                          className="sp-kk"
                          onClick={() => {
                            doRepeal(li);
                          }}
                        ></span>
                      )}
                      <span
                        className={li.Flag === 0 ? "sp-kk-flag" : "sp-kk-flag-red"}
                        onClick={() => {
                          doFlag(li);
                        }}
                      ></span>
                      <p className={li.Status === 9 ? "text-line-through" : ""}>
                        {li.Name}
                        <span className="nameCut">
                          <span className="riqi">{li.StartTime !== 0 ? dayjs.unix(li.StartTime).format("M月DD日") : ""}</span>
                          <span className="project">{li.Status === 1 ? "收集箱" : ""}</span>
                          <span className="project">{contextStore.tableShow(li.ContextId)}</span>
                        </span>
                      </p>
                      <Popover
                        content={
                          <div>
                            <div
                              className="middle-table-li-right-item"
                              onClick={() => {
                                modelContextOpen(li);
                              }}
                            >
                              {markIcon(15)}
                              <p className="sandyx-menu">设置标签</p>
                            </div>
                            <div
                              className="middle-table-li-right-item"
                              onClick={() => {
                                modelProjectOpen(li);
                              }}
                            >
                              {listIcon(15)}
                              <p className="sandyx-menu">移动到</p>
                            </div>
                            <Popconfirm
                              title="删除"
                              description="您确认要删除吗?"
                              okText="删除"
                              cancelText="取消"
                              okButtonProps={{
                                className: "bg-emerald-500"
                              }}
                              onConfirm={() => {
                                delAction(li);
                              }}
                            >
                              <div className="middle-table-li-right-item">
                                {delIcon(15)} <p className="sandyx-menu">删除</p>
                              </div>
                            </Popconfirm>
                          </div>
                        }
                        trigger="click"
                        placement="bottomRight"
                      >
                        <span className="jl-gd">
                          <span className="jl-gd-sd"></span>
                        </span>
                      </Popover>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Modal open={contextModelOpen} centered={true} onCancel={modelContextCancel} footer={null} forceRender>
        <Form size="large" layout="horizontal" style={{ marginTop: "25px" }} labelCol={{ span: 2 }} onFinish={editActionContext} form={formChangeContext}>
          <Space>
            <Form.Item name="ContextId">
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "400px"
                }}
                placeholder="选择标签"
                options={contextStore.options}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
      <Modal open={projectModelOpen} centered={true} onCancel={modelProjectCancel} footer={null}>
        <Form size="large" layout="horizontal" style={{ marginTop: "25px" }} labelCol={{ span: 2 }} onFinish={editActionProject} form={formChangeProject}>
          <Space>
            <Form.Item name="ProjectId">
              <Select
                allowClear
                style={{
                  width: "400px"
                }}
                placeholder="移动到"
                options={projectStore.middleRightOptions}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </>
  );
});

export { Middle };
