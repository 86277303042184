import { makeAutoObservable, runInAction } from "mobx";
import { addProject, delProject, getOneProject, getProject } from "@utils/db";
import { saveProject } from "@utils/api";
import dayjs from "dayjs";
import { fileIcon, findColor } from "@utils/icon";
import { sort } from "@utils/sort";

class ProjectStore {
  allData = null;
  seqTypeOptions = [
    { label: "顺序", value: 0 },
    { label: "平行", value: 1 }
  ];
  statusOptions = [
    { label: "活跃", value: 0 },
    { label: "暂停", value: 3 }
  ];
  showModel = false;
  treeData = [];
  options = [];
  actionOptions = [];
  menuOptions = [];
  middleRightOptions = [];
  arr = {};
  defaultArray = {
    Id: 0,
    Id_Local: 0,
    Mid: 0,
    FId: 0,
    FId_Local: 0,
    UserId: 0,
    Type: 0,
    SeqType: 1,
    pClass: 0,
    Color: 0,
    FolderId: 0,
    FolderId_Local: 0,
    ContactId: null,
    Access: null,
    Name: "",
    Description: "",
    Status: 0,
    StartTime: 0,
    DueTime: 0,
    DurationTime: 0,
    UpdateTime: 0,
    Review: null,
    ReviewRate: 0,
    ReviewStartTime: 0,
    ReviewEndTime: 0,
    CompleteTime: 0,
    ifAuto: 0,
    CreateTime: 0,
    Seq: 0,
    FileFlag: 0,
    FcFlag: 0,
    Name_Seg: "",
    AccessPermission: 0,
    TimeZone: null,
    Background: "",
    Display: 0,
    Display2: 0,
    Display3: 0,
    DisplayDone: 0,
    DisplayDetail: 0,
    DisplayDash: 0,
    DisplayStatus: 0,
    DisplayGroup: 0,
    Sort: 0,
    ViewMode: 0,
    CalendarAccount: null,
    Configure1: null,
    Configure2: null
  };
  saveData = {
    Op: 1,
    UserId: 0,
    Id_Local: 0,
    Type: 0,
    SeqType: 0, //0:顺序 1:平行
    ContactId: null,
    ContextId: "",
    CreateTime: "",
    pClass: 0,
    FolderId: 0,
    FolderId_Local: 0,
    Name: "pro_aa",
    Description: 0,
    Status: 0, //状态 0:活跃 3:暂停
    StartTime: 0,
    DueTime: 0,
    DurationTime: 0,
    FId: 0,
    FId_Local: 0,
    ReviewRate: 0,
    ifAuto: 0,
    Color: 0,
    AccessPermission: 0,
    Display: 0,
    Display2: 0,
    Review: 0,
    ReviewEndTime: 0,
    ReviewStartTime: 0,
    isShow: 1,
    Seq: 0,
    Sort: 0
  };

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  switchModel = () => {
    this.showModel = !this.showModel;
  };
  defaultArr = () => {
    this.arr = this.defaultArray;
  };

  updateAllData = async (arr = null) => {
    let data = [];
    if (arr) {
      data = arr;
    } else {
      data = await getProject().then((res) => {
        return res;
      });
    }
    runInAction(() => {
      this.allData = data;
      this.defaultArr();
      this.getTreeData();
      this.getOptions();
      this.updateActionOptions();
    });
  };

  getTreeData = async () => {
    const folder = this.rootStore.folderStore.allData;
    const project = this.allData.map((item) => {
      return {
        title: item.Name,
        key: item.Id,
        icon: item.Type !== null ? findColor(item.Type) : findColor("0"),
        children: null,
        type: 2,
        FolderId: item.FolderId,
        FId: item.FId
      };
    });

    let newProject = [];
    if (project.length > 0) {
      project.forEach((item) => {
        let child = [];
        project.forEach((p) => {
          if (p.FId === item.key) {
            child.push(p);
          }
        });
        let one = { ...item };
        one.children = child.length > 0 ? child : null;
        newProject.push(one);
      });
    }
    newProject = sort(newProject, "key", "asc");

    let group = folder.map((item) => {
      let child = [];
      newProject
        .filter((item) => {
          return item.FId === 0;
        })
        .forEach((c) => {
          if (c.FolderId === item.Id) {
            child.push(c);
          }
        });
      return {
        title: item.Name,
        key: item.Id,
        icon: fileIcon(),
        children: child.length ? child : null,
        type: 1
      };
    });
    group = sort(group, "key", "asc");
    newProject.forEach((c) => {
      if (c.FolderId === 0 && c.FId === 0) {
        group.push(c);
      }
    });
    group.push({
      title: "无分组",
      key: 0,
      icon: null,
      children: null,
      type: 2,
      FolderId: 0,
      FId: 0
    });

    runInAction(() => {
      this.treeData = group;
    });
  };

  getOptions = async (params) => {
    const folder = this.rootStore.folderStore.allData;
    const project = this.allData;
    const optionFolder = folder.map((item) => {
      return {
        label: item.Name,
        value: item.Id + "-1"
      };
    });
    let optionProject = [];
    if ((params && params.children === null) || params === undefined || !params) {
      project.forEach((item) => {
        if (item.FId === 0 && item.FolderId === 0 && (params === undefined || (params && params.key !== item.Id))) {
          optionProject.push({
            label: item.Name,
            value: item.Id + "-2"
          });
        }
      });
    }
    const option = [
      {
        label: "分组",
        options: optionProject
      },
      {
        label: "文件夹",
        options: optionFolder
      }
    ];
    runInAction(() => {
      this.options = option;
    });
  };

  updateActionOptions = async () => {
    const options = this.allData.map((item) => {
      return {
        label: item.Name,
        value: item.Id
      };
    });
    const menu = this.allData.map((item) => {
      return {
        label: item.Name,
        key: item.Id
      };
    });
    let middleRightOptions = [{ label: "无", value: 0 }, { label: "收集箱", value: -1 }, ...options];
    middleRightOptions.push();
    runInAction(() => {
      this.actionOptions = options;
      this.menuOptions = menu;
      this.middleRightOptions = middleRightOptions;
    });
  };

  delProject = async (Id) => {
    const one = await getOneProject(Id);
    const params = {
      Op: 3,
      Id: Id,
      Id_Local: one.Id_Local,
      ContactId: one.ContactId,
      DelFlag: 1,
      UserId: this.rootStore.loginStore.userinfo.UserId
    };
    return await saveProject(params).then((res) => {
      if (res.code === 0) {
        delProject(Id);
      }
      this.updateAllData();
      return res;
    });
  };

  saveProject = async (form) => {
    let params = { ...this.saveData };
    params.Op = 1;
    params.Name = form.Name;
    params.SeqType = form.SeqType;
    params.Status = form.Status;
    params.Type = form.Type;
    params.Name = form.Name;
    params.UserId = this.rootStore.loginStore.userinfo.UserId;
    params.Description = form.Description !== undefined ? form.Description : "";
    params.FolderId = 0;
    params.FId = 0;
    if (form.FolderId) {
      const father = form.FolderId.split("-");
      if (father[1] === 1) {
        params.FolderId = parseInt(father[0]);
      } else {
        params.FId = parseInt(father[0]);
      }
    }
    if (form.Id > 0) {
      params.Id = form.Id;
      params.Op = 2;
      params.UpdateTime = dayjs().unix();
    } else {
      params.CreateTime = dayjs().unix();
    }
    const { code, msg, data } = await saveProject(params);
    if (code === 0) {
      let newOne = { ...this.arr };
      newOne.Id = data.data.Id;
      newOne.Name = params.Name;
      newOne.SeqType = params.SeqType;
      newOne.Status = params.Status;
      newOne.Type = params.Type;
      newOne.Name = params.Name;
      newOne.UserId = params.UserId;
      newOne.CreateTime = params.CreateTime;
      newOne.Description = params.Description;
      newOne.FolderId = params.FolderId;
      newOne.FId = params.FId;
      addProject(newOne);
    }
    await this.updateAllData();
    return { code, msg };
  };
}

export default ProjectStore;
