import {observer} from 'mobx-react-lite'
import {DatePicker, Dropdown, Input, message} from 'antd'
import dayjs from 'dayjs'
import {useState} from 'react'
import {useStore} from '@/store'
import {runInAction} from 'mobx'

const MiddleQuick = observer(function() {
  const {actionStore, projectStore} = useStore()
  const [quickDate, setQuickDate] = useState(0)
  const [quickDateShow, setQuickDateShow] = useState(null)
  const [quickProject, setQuickProject] = useState(0)
  const [quickName, setQuickName] = useState('')
  const [quickPlaceholder, setQuickPlaceholder] = useState('想到什么记录下来')
  const [messageApi, contextHolder] = message.useMessage();

  const addAction = async(e) => {
    const params = {
      Name     : e.target.value,
      StartTime: quickDate === null ? dayjs().startOf('hour').add(1, 'hour').unix() : quickDate,
      ProjectId: quickProject ? quickProject : 0
    }
    if (e.target.value === '' || e.target.value === null || e.target.value === undefined) {
      return
    }
    setQuickDate(0)
    setQuickDateShow(null)
    setQuickProject(null)
    setQuickName('')
    actionStore.quickAddShowSwitch(false)
    await actionStore.saveAction(params).then((res) => {
      if (res.code === 0) {
        messageApi.open({type: 'success', content: '已添加一项行动'});
      } else {
        messageApi.open({type: 'error', content: res.msg});
      }
    })
  }
  const quickClick = () => {
    actionStore.quickAddShowSwitch(true)
    if (actionStore.editId !== null) {
      runInAction(() => {
        actionStore.editData = null
        actionStore.editId = null
      })
    }
  }

  const changeQuickProject = (e) => {
    const key = parseInt(e.key)
    projectStore.menuOptions.forEach(item => {
      if (item.key === key) {
        setQuickPlaceholder('创建行动至' + item.label)
      }
    })
    setQuickProject(key)
  }

  const changeQuickDate = (e) => {
    setQuickDate(e ? e.unix() : 0)
    setQuickDateShow(e)
  }

  return (
      <>
        {contextHolder}
        <div className={actionStore.quickAddShow ? 'record huid pkl' : 'record huid '} onClick={quickClick}>
          <Input className='record-text' bordered='false'
                 value={quickName}
                 onChange={(e) => {
                   setQuickName(e.target.value)
                 }}
                 onPressEnter={addAction}
                 maxLength={100}
                 placeholder={quickPlaceholder} />
          <div className={actionStore.quickAddShow ? 'record-yc r' : 'record-yc r hide'}>
            <Dropdown
                menu={{items: projectStore.menuOptions, onClick: changeQuickProject}} trigger={['click']}
            >
              <img className='calendar r' style={{marginTop: '16px'}} src={require("../assets/images/adf.jpg")} />
            </Dropdown>
            <div className='middle-table-top-space-line'></div>
            <DatePicker bordered={false} className='r' placeholder='' value={quickDateShow} onChange={changeQuickDate} />
          </div>
          <span className='lan-biu'></span>
          <div className='zuo-baid'></div>
          <div className='you-baid'></div>
        </div>
      </>
  )
})

export {MiddleQuick}