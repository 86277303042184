import { http } from "@utils/http";
import { formatData } from "@utils/params";
import { Encrypt } from "@utils/aes";

const loginUrl = "api/user/login"; //登录
const allDataUrl = "api/home/index"; //所有数据
const saveProjectUrl = "api/syncProject"; //保存分组
const saveFolderUrl = "api/syncFolder"; //保存文件夹
const saveContextUrl = "api/syncContext"; //保存标签
const saveActionUrl = "api/syncAction"; //保存行动
const getOneNoteUrl = "api/getOneNote"; //获取单个文章
const getCaptchaImgUrl = "api/get_captcha_img"; //获取验证码图片
const sendSmsUrl = "api/send_sms_by_captcha"; //发送短信验证码
const registerUrl = "api/user/register"; //注册

export const register = async (params) => {
  const newParams = formatData(Encrypt(params), registerUrl);
  const { data } = await http.post(registerUrl, newParams);
  return data;
};

export const sendSms = async (params) => {
  const newParams = formatData(Encrypt(params), sendSmsUrl);
  const { data } = await http.post(sendSmsUrl, newParams);
  return data;
};

export const getCaptchaImg = async () => {
  const newParams = formatData([], getCaptchaImgUrl);
  const { data } = await http.post(getCaptchaImgUrl, newParams);
  return data;
};

export const login = async (params) => {
  const newParams = formatData(Encrypt(params), loginUrl);
  const { data } = await http.post(loginUrl, newParams);
  return data;
};

export const saveProject = async (params) => {
  const newParams = formatData(Encrypt(params), saveProjectUrl);
  const { data } = await http.post(saveProjectUrl, newParams);
  return data;
};

export const saveContext = async (params) => {
  const newParams = formatData(Encrypt(params), saveContextUrl);
  const { data } = await http.post(saveContextUrl, newParams);
  return data;
};

export const saveFolder = async (params) => {
  const newParams = formatData(Encrypt(params), saveFolderUrl);
  const { data } = await http.post(saveFolderUrl, newParams);
  return data;
};

export const saveAction = async (params, type = "h5", config = null) => {
  const newParams = formatData(Encrypt(params, type), saveActionUrl, config);
  const { data } = await http.post(saveActionUrl, newParams);
  return data;
};

export const getAllData = async (params) => {
  const newParams = formatData(Encrypt(params), allDataUrl);
  const { data } = await http.post(allDataUrl, newParams, { timeout: 60000 });
  return data;
};

export const getOneNote = async (params, type = "h5", config = null) => {
  const newParams = formatData(Encrypt(params, type), getOneNoteUrl, config);
  const { data } = await http.post(getOneNoteUrl, newParams);
  return data;
};
