import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function clsxMerge(...inputs) {
  return twMerge(clsx(inputs));
}

export function isValidUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

export function getUrlFromString(str) {
  if (isValidUrl(str)) return str;
  try {
    if (str.includes('.') && !str.includes(' ')) {
      return new URL(`https://${str}`).toString();
    }
  } catch (e) {
    return null;
  }
}
