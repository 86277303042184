import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Menu } from "antd";
import { useStore } from "@/store";
import { reaction, runInAction } from "mobx";
import noteStore from "@store/note.Store";

const MainLeftMenu = observer(function () {
  const { actionStore } = useStore();
  const menu = actionStore.getMenu();
  useEffect(() => {
    reaction(
      () => actionStore.allData,
      () => {
        if (actionStore.allData.length > 0) {
          actionStore.updateMenu();
        }
      },
      { fireImmediately: true }
    );
  }, []);

  const onClick = (e) => {
    runInAction(() => {
      actionStore.editId = null;
      actionStore.editData = null;
      actionStore.groupType = e.type;
    });
  };

  return (
    <div>
      <ul>
        {menu.map((item) => (
          <li className="flex h-8 cursor-pointer items-center justify-between px-6 py-4 hover:bg-gray-100" key={item.id} onClick={() => onClick(item)}>
            <div className="text- flex items-center text-sm">
              {item.icon}
              <p className="pl-2">{item.text}</p>
            </div>

            <p className="text-xs">{item.number}</p>
          </li>
        ))}
      </ul>
    </div>
  );
});

export { MainLeftMenu };
