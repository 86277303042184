import { Tabs } from "antd";
import { MainLeftClass } from "@components/mainLeftClass";
import { MainLeftContext } from "@components/mainLeftContext";

const MainLeftGroup = () => {
  const items = [
    {
      key: "1",
      label: `分组`,
      children: <MainLeftClass />
    },
    {
      key: "2",
      label: `标签`,
      children: <MainLeftContext />
    }
  ];

  return (
    <>
      <div className="zc-fbl clearfix">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </>
  );
};
export { MainLeftGroup };
