import { observer } from "mobx-react-lite";
import { useStore } from "@/store";

const RightEmpty = observer(function () {
  const { actionStore } = useStore();
  return (
    <>
      <div
        className="ploughing l"
        onClick={() => {
          actionStore.quickAddShowSwitch(false);
        }}
      >
        <div className="flex h-full items-center justify-center">
          <img alt="empty" src={require("../assets/images/empty.png")} />
        </div>
        {/*<p className="tk-text">条款&nbsp;-&nbsp;隐私权</p>*/}
      </div>
    </>
  );
});

export { RightEmpty };
