import { Button, Form, Input, message, Space } from "antd";
import { LockKeyhole, User2 } from "lucide-react";

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "@images/login/logo.png";
import background from "@images/login/background.png";
import { getCaptchaImg, register, sendSms } from "@utils/api";
import { checkPhone } from "@utils/verify";
import { getStorage, setStorage } from "@/utils";

function Register() {
  const smsTimeKey = "sendSmsTime";
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState("");
  const [captchaStatus, setCaptchaStatus] = useState("validating");
  const [captchaError, setCaptchaError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneStatus, setPhoneStatus] = useState("validating");
  const [phoneError, setPhoneError] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [smsStatus, setSmsStatus] = useState(true);
  const [smsNumber, setSmsNumber] = useState(60);
  const [smsTimer, setSmsTimer] = useState(null);

  useEffect(() => {
    const time = getStorage(smsTimeKey);
    if (time) {
      if (Date.now() - time > 60000) {
        setSmsNumber(60);
        setSmsStatus(true);
      } else {
        setSmsStatus(false);
        setSmsNumber(60 - Math.floor((Date.now() - time) / 1000));
        setSmsTimer(
          setInterval(() => {
            setSmsNumber((smsNumber) => smsNumber - 1);
          }, 1000)
        );
      }
    }
    getCaptcha().then();
    return () => {
      clearInterval(smsTimer);
    };
  }, []);

  useEffect(() => {
    if (!smsStatus && smsNumber === 0) {
      clearInterval(smsTimer);
      setSmsStatus(true);
      setSmsNumber(60);
    }
  }, [smsNumber, smsStatus, smsTimer]);

  const onFinish = (values) => {
    if (!checkPhone(phone)) {
      setPhoneStatus("error");
      setPhoneError("请输入正确的手机号");
      return;
    }

    register(values).then((res) => {
      if (res.code === 0) {
        messageApi.success("注册成功", 3);
        navigate("/login");
      } else {
        messageApi.error(res.msg);
      }
    });
  };
  const onFinishFailed = () => {
    if (!checkPhone(phone)) {
      setPhoneStatus("error");
      setPhoneError("请输入正确的手机号");
    }
  };

  const sendMsg = () => {
    if (!checkPhone(phone)) {
      setPhoneStatus("error");
      setPhoneError("请输入正确的手机号");
      return;
    }
    if (captcha.length !== 4) {
      setCaptchaStatus("error");
      setCaptchaError("请输入图形验证码");
      return;
    }
    const data = {
      phone: phone,
      captcha: captcha,
      key: captchaKey
    };

    sendSms(data).then((res) => {
      if (res.code === 0) {
        setSmsStatus(false);
        setStorage(smsTimeKey, Date.now());
        messageApi.success("验证码已发送", 3);
        setSmsTimer(
          setInterval(() => {
            setSmsNumber((smsNumber) => smsNumber - 1);
          }, 1000)
        );
      } else {
        messageApi.error(res.msg);
      }
    });
  };
  const phoneChange = (e) => {
    setPhoneStatus("validating");
    setPhoneError("");
    setPhone(e.target.value);
  };
  const captchaChange = (e) => {
    setCaptchaStatus("validating");
    setCaptchaError("");
    setCaptcha(e.currentTarget.value);
  };

  const [captchaImg, setCaptchaImg] = useState("");
  const [captchaKey, setCaptchaKey] = useState("");
  const getCaptcha = async () => {
    await getCaptchaImg().then((res) => {
      if (res.code === 0) {
        setCaptchaImg("data:" + res.data.captcha.img);
        setCaptchaKey(res.data.captcha.key);
      } else {
        messageApi.error(res.msg);
      }
    });
  };
  return (
    <>
      {" "}
      {contextHolder}
      <div className="flex h-full w-auto flex-row">
        <div className="h-full w-1/2 bg-[#fafafc]">
          <div className="flex h-full flex-col justify-between">
            <div className="relative left-[15%] top-[10%]">
              <div>
                <img src={logo} alt="logo" className="h-12 w-12" />
              </div>
              <div className="pl-1 pt-2 ">
                <p className="text-gray-400">耕耘忠实遵循GTD时间管理体系</p>
                <p className="text-gray-400">帮助您自信地管理和完成日程安排、任务和笔记</p>
              </div>
            </div>
            <div className="flex justify-center">
              <img src={background} alt="background" className="h-[80%]" />
            </div>
          </div>
        </div>
        <div className="h-full w-1/2">
          <div className="h-full">
            <div className="relative top-[20%] mx-20">
              <div className="text-4xl font-medium">欢迎使用耕耘</div>
              <div className="mt-2 text-sm text-gray-400">
                已有账号,直接
                <Link to={"/login"} className="text-sky-500">
                  登录
                </Link>
              </div>
            </div>
            <div className="relative top-[40%] mx-20 w-[50%]">
              <div className="mb-2 text-2xl text-emerald-600">注册账号</div>
              <Form name="normal_login" autoComplete="on" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Form.Item
                  value={phone}
                  onChange={phoneChange}
                  validateStatus={phoneStatus}
                  help={phoneError}
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "请填写手机号或者账号"
                    }
                  ]}
                >
                  <Input prefix={<User2 size={18} className="text-stone-500" />} size={"large"} placeholder="手机号" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "请填写密码"
                    }
                  ]}
                >
                  <Input.Password prefix={<LockKeyhole size={18} className="text-stone-500" />} size={"large"} type="password" placeholder="请设置密码" />
                </Form.Item>
                <Form.Item validateStatus={captchaStatus} help={captchaError}>
                  <Space.Compact block>
                    <Input size={"large"} placeholder="图形验证码" value={captcha} onChange={captchaChange} maxLength={4} />
                    <img src={captchaImg} alt="图形验证码" onClick={getCaptcha} />
                  </Space.Compact>
                </Form.Item>
                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "请填写手机验证码"
                    }
                  ]}
                >
                  <Space.Compact block>
                    <Input size={"large"} placeholder="手机验证码" maxLength={4} />
                    {smsStatus ? (
                      <Button size={"large"} onClick={sendMsg}>
                        发送短信
                      </Button>
                    ) : (
                      <Button size={"large"}>{smsNumber}</Button>
                    )}
                  </Space.Compact>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" className="float-right w-28 bg-emerald-500">
                    确认注册
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
