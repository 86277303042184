import React from "react";
import LoginStore from "@store/login.Store";
import MainStore from "@store/main.Store";
import ProjectStore from "@store/project.Store";
import FolderStore from "@store/folder.Store";
import ContextStore from "@store/context.Store";
import ActionStore from "@store/action.Store";
import ActionSubStore from "@store/actionSub.Store";
import PerspectiveStore from "@store/perspective.Store";
import NoteStore from "@store/note.Store";

class RootStore {
  constructor() {
    this.loginStore = new LoginStore(this);
    this.mainStore = new MainStore(this);
    this.projectStore = new ProjectStore(this);
    this.folderStore = new FolderStore(this);
    this.contextStore = new ContextStore(this);
    this.actionStore = new ActionStore(this);
    this.actionSubStore = new ActionSubStore(this);
    this.perspectiveStore = new PerspectiveStore(this);
    this.noteStore = new NoteStore(this);
  }
}

const StoresContext = React.createContext(new RootStore());
export const useStore = () => React.useContext(StoresContext);
