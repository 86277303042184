import { makeAutoObservable, runInAction } from "mobx";
import { addActionSub, delActionSub, getActionSub, getOneActionSub } from "@utils/db";
import dayjs from "dayjs";
import { saveAction } from "@utils/api";
import { randomNum } from "@/utils";
import { sort } from "@utils/sort";

class ActionSubStore {
  allData = [];
  subData = [];
  arr = {
    Id: 0,
    Id_Local: 0,
    Name: "",
    ActionId: 0,
    Status: 0,
    UserId: 0,
    CompleteTime: 0,
    CompleteUserId: null,
    CreateTime: 0,
    Description: "",
    DueTime: 0,
    DurationTime: 0,
    Seq: 0,
    ShareCreateUserId: null,
    StartTime: 0
  };
  saveData = {
    Op: 22,
    UserId: 0,
    Id_Local: 0,
    ActionId: 0,
    StartTime: 0,
    DueTime: 0,
    DurationTime: 0,
    Name: "",
    Status: 0
  };

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  updateAllData = async (sub = null) => {
    let data = [];
    if (sub) {
      data = sub;
    } else {
      data = await getActionSub().then((res) => {
        return res;
      });
    }
    runInAction(() => {
      this.allData = sort(data, "CreateTime");
    });
  };

  getSub = async (ActionId) => {
    let data = [];
    this.allData.forEach((item) => {
      if (item.ActionId === ActionId) {
        data.push(item);
      }
    });
    runInAction(() => {
      this.subData = sort(data, "ID");
    });
  };

  doDel = async (item) => {
    if (item.Id === 0) {
      return { code: 500, msg: "操作失败，请稍候重试。" };
    }
    const params = {
      Id: item.Id,
      UserId: item.UserId,
      Id_Local: item.Id_Local,
      Op: 24
    };
    delActionSub(item.Id);
    this.updateAllData().then(() => {
      this.getSub(item.ActionId);
    });
    return await saveAction(params)
      .then((res) => {
        if (res.code !== 0) {
          addActionSub(item);
          this.updateAllData().then(() => {
            this.getSub(item.ActionId);
          });
        }
        return res;
      })
      .catch((e) => {
        addActionSub(item);
        this.updateAllData().then(() => {
          this.getSub(item.ActionId);
        });
        return { code: 500, msg: "请求失败,请稍后重试" };
      });
  };

  saveActionSub = async (form) => {
    let params = { ...this.saveData };
    let old = null;
    params.Op = 22;
    params.UserId = this.rootStore.loginStore.userinfo.UserId;
    for (let key in form) {
      if (form[key] !== undefined) {
        params[key] = form[key];
      }
    }

    params.Id_Local = randomNum(9);

    if (form.Id > 0) {
      params.Id = form.Id;
      params.Op = 23;
      await getOneActionSub(form.Id).then((res) => {
        old = res;
      });
    } else {
      params.CreateTime = dayjs().unix();
    }

    let newOne = { ...this.arr };
    for (let key in newOne) {
      if (params[key] !== undefined) {
        newOne[key] = params[key];
      }
    }
    delActionSub(0);
    addActionSub(newOne);
    this.updateAllData().then(() => {
      this.getSub(params.ActionId);
    });

    return await saveAction(params)
      .then((res) => {
        if (res.code === 0) {
          if (form.Id > 0) {
            return res;
          }
          newOne.Id = res.data.data.Id;
          addActionSub(newOne);
        }
        if (form.Id > 0) {
          addActionSub(old);
        } else {
          delActionSub(0);
        }
        this.updateAllData().then(() => {
          this.getSub(params.ActionId);
        });
        return res;
      })
      .catch((e) => {
        if (form.Id > 0) {
          addActionSub(old);
        } else {
          delActionSub(0);
        }
        this.updateAllData().then(() => {
          this.getSub(params.ActionId);
        });
        return { code: 500, msg: "操作失败，请稍候重试。" };
      });
  };
}

export default ActionSubStore;
