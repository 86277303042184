const findColor = (key, size = 18) => {
  const arr = {
    0 : cyanGreen(size),
    1 : red(size),
    2 : blue(size),
    3 : amber(size),
    4 : purple(size),
    5 : green(size),
    6 : pink(size),
    7 : cyan(size),
    8 : brown(size),
    9 : lime(size),
    10: indigo(size)
  }
  if (key !== null) {
    if (!Number.isInteger(key)) {
      key = parseInt(key)
    }
    if (arr[key]) {

      return arr[key]
    }
    return arr[0]
  }
  let data = [];
  for (const key in arr) {
    data.push({label: arr[key], value: parseInt(key)})
  }

  return data
}

//循环
function repeatIcon(size = 18) {
  return (
      <>
        <svg t='1678775509870' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='73287' width={size} height={size}>
          <path
              d='M512.271856 139.636751 512.271856 0 326.090231 186.181624l186.181624 186.181624L512.271856 232.727563c153.599467 0 279.272437 125.67297 279.272437 279.272437 0 46.544873-13.963782 93.090812-32.582157 130.327563l69.817843 69.817843c32.582157-60.508655 55.854061-125.67297 55.854061-200.145406C884.635104 307.2 717.071856 139.636751 512.271856 139.636751zM512.271856 791.272437c-153.599467 0-279.272437-125.67297-279.272437-279.272437 0-46.545939 13.963782-93.090812 32.582157-130.327563l-69.817843-69.817843c-32.582157 60.508655-55.854061 125.67297-55.854061 200.145406 0 204.8 167.563249 372.363249 372.363249 372.363249l0 139.636751 186.181624-186.181624-186.181624-186.181624L512.272922 791.272437z'
              fill='#515151' p-id='73288'></path>
        </svg>
      </>
  )
}

//空闲
function freeIcon(size = 18) {
  return (
      <>
        <svg t='1678649721938' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='72204' width={size} height={size}>
          <path
              d='M832 448h-64v-32c0-52.8-43.2-96-96-96H160c-52.8 0-96 43.2-96 96v288c0 140.8 115.2 256 256 256h192c125.676 0 230.947-91.783 252.115-211.627C783.825 760.793 807.11 768 832 768c70.4 0 128-57.6 128-128v-64c0-70.4-57.6-128-128-128zM352 832c-88.366 0-160-71.634-160-160 0-17.673 14.327-32 32-32s32 14.327 32 32c0 53.019 42.981 96 96 96 17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32z m544-192c0 35.2-28.8 64-64 64s-64-28.8-64-64V512h64c35.2 0 64 28.8 64 64v64zM224 256c17.6 0 32-14.4 32-32V96c0-17.6-14.4-32-32-32s-32 14.4-32 32v128c0 17.6 14.4 32 32 32zM416 256c17.6 0 32-14.4 32-32V96c0-17.6-14.4-32-32-32s-32 14.4-32 32v128c0 17.6 14.4 32 32 32zM608 256c17.6 0 32-14.4 32-32V96c0-17.6-14.4-32-32-32s-32 14.4-32 32v128c0 17.6 14.4 32 32 32z'
              p-id='72205' fill='#515151'></path>
        </svg>
      </>
  )
}

//提醒
function remindIcon(size = 18) {
  return (
      <>
        <svg t='1678645238194' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='70012' width={size} height={size}>
          <path
              d='M808 712l-48-108.8V448c0-102.4-65.6-195.2-160-232-3.2-44.8-41.6-80-88-80s-83.2 35.2-88 80c-96 36.8-160 129.6-160 232v155.2l-48 108.8c-6.4 14.4-4.8 30.4 3.2 43.2 8 12.8 22.4 20.8 38.4 20.8h508.8c16 0 30.4-8 38.4-20.8s9.6-30.4 3.2-43.2zM512 880c44.8 0 80-35.2 80-80H432c0 44.8 35.2 80 80 80z'
              fill='#515151' p-id='70013'></path>
        </svg>
      </>
  )
}

//时间
function timeIcon(size = 18) {
  return (
      <>
        <svg t='1678633327738' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='68964' width={size} height={size}>
          <path
              d='M554.666667 516.266667l102.4 102.4-59.733334 59.733333-123.733333-123.733333H469.333333V341.333333h85.333334v174.933334zM512 853.333333c-187.733333 0-341.333333-153.6-341.333333-341.333333s153.6-341.333333 341.333333-341.333333 341.333333 153.6 341.333333 341.333333-153.6 341.333333-341.333333 341.333333z m0-85.333333c140.8 0 256-115.2 256-256s-115.2-256-256-256-256 115.2-256 256 115.2 256 256 256z'
              fill='#515151' p-id='68965'></path>
        </svg>
      </>
  )
}

//子项
function subIcon(size = 18) {
  return (
      <>
        <svg t='1678632776023' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='66500' width={size} height={size}>
          <path d='M800 800h-640A32 32 0 0 1 128 768V128a32 32 0 0 1 64 0v608h608a32 32 0 0 1 0 64z' fill='#515151' p-id='66501'></path>
          <path
              d='M672 992a30.08 30.08 0 0 1-22.4-9.6 30.72 30.72 0 0 1 0-44.8L818.56 768l-168.96-169.6a31.36 31.36 0 0 1 44.8-44.8l192 192a30.72 30.72 0 0 1 0 44.8l-192 192a30.08 30.08 0 0 1-22.4 9.6zM864 288h-256a32 32 0 0 1 0-64h256a32 32 0 0 1 0 64z'
              fill='#515151' p-id='66502'></path>
          <path d='M736 416A32 32 0 0 1 704 384V128a32 32 0 0 1 64 0v256a32 32 0 0 1-32 32z' fill='#515151' p-id='66503'></path>
        </svg>
      </>
  )
}

//删除
function delIcon(size = 18) {
  return (
      <>
        <svg t='1678541272636' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='30188' width={size} height={size}>
          <path
              d='M861.184 192.512q30.72 0 50.688 10.24t31.744 25.6 16.384 33.28 4.608 33.28q0 7.168-0.512 11.264t-0.512 7.168l0 6.144-67.584 0 0 537.6q0 20.48-8.192 39.424t-23.552 33.28-37.376 23.04-50.688 8.704l-456.704 0q-26.624 0-50.176-8.192t-40.448-23.04-26.624-35.84-9.728-47.616l0-527.36-63.488 0q-1.024-1.024-1.024-5.12-1.024-5.12-1.024-31.744 0-13.312 6.144-29.696t18.432-30.208 31.744-23.04 46.08-9.216l91.136 0 0-62.464q0-26.624 18.432-45.568t45.056-18.944l320.512 0q35.84 0 49.664 18.944t13.824 45.568l0 63.488q21.504 1.024 46.08 1.024l47.104 0zM384 192.512l320.512 0 0-64.512-320.512 0 0 64.512zM352.256 840.704q32.768 0 32.768-41.984l0-475.136-63.488 0 0 475.136q0 21.504 6.656 31.744t24.064 10.24zM545.792 839.68q17.408 0 23.552-9.728t6.144-31.232l0-475.136-63.488 0 0 475.136q0 40.96 33.792 40.96zM738.304 837.632q18.432 0 24.576-9.728t6.144-31.232l0-473.088-64.512 0 0 473.088q0 40.96 33.792 40.96z'
              p-id='30189' fill='#515151'></path>
        </svg>
      </>
  )
}

//列表
function listIcon(size = 18) {
  return (
      <>
        <svg t='1678541125222' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='28940' width={size} height={size}>
          <path
              d='M1024 176v144c0 8.8-7.2 16-16 16H240c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h768c8.8 0 16 7.2 16 16zM1024 432v144c0 8.8-7.2 16-16 16H240c-8.8 0-16-7.2-16-16V432c0-8.8 7.2-16 16-16h768c8.8 0 16 7.2 16 16zM1024 688v144c0 8.8-7.2 16-16 16H240c-8.8 0-16-7.2-16-16V688c0-8.8 7.2-16 16-16h768c8.8 0 16 7.2 16 16zM176 176v144c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h144c8.8 0 16 7.2 16 16zM176 432v144c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16V432c0-8.8 7.2-16 16-16h144c8.8 0 16 7.2 16 16zM176 688v144c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16V688c0-8.8 7.2-16 16-16h144c8.8 0 16 7.2 16 16z'
              p-id='28941' fill='#515151'></path>
        </svg>
      </>
  )
}

//标签
function markIcon(size = 18) {
  return (
      <>
        <svg t='1678042609425' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='4048' width={size} height={size}>
          <path
              d='M751.889579 224.458121c-17.051351-23.825638-44.848441-39.476083-76.381621-39.476083l-513.883907 0.467651c-51.621704 0-93.432972 41.34464-93.432972 92.966345l0 467.166909c0 51.621704 41.811268 92.966345 93.432972 92.966345l513.883907 0.467651c31.534204 0 59.33027-15.650445 76.381621-39.47506l203.918319-287.541879L751.889579 224.458121z'
              p-id='4049' fill='#515151'></path>
        </svg>
      </>
  )
}

//文件夹
function fileIcon(size = 18) {
  return (
      <>
        <svg t='1677687793833' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='8579' width={size} height={size}>
          <path d='M880 298.4H521L403.7 186.2c-1.5-1.4-3.5-2.2-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z' p-id='8580' fill='#707070'></path>
        </svg>
      </>
  )
}

//活跃
function activityIcon(size = 18) {
  return (
      <>
        <svg t='1678000838154' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='21966' id='mx_n_1678000838154' width={size} height={size}>
          <path
              d='M541.04064 834.70336s-0.43008 0 0 0a40.8576 40.8576 0 0 1-38.99392-29.14304L376.03328 383.3856l-59.57632 181.30944a40.57088 40.57088 0 0 1-38.58432 27.8528H151.42912a40.69376 40.69376 0 0 1-40.71424-40.71424 40.96 40.96 0 0 1 40.71424-40.71424h96.8704l91.29984-276.8896c5.57056-16.71168 21.85216-27.42272 39.44448-27.8528a41.30816 41.30816 0 0 1 38.56384 29.14304l124.29312 417.4848 94.28992-308.18304a40.93952 40.93952 0 0 1 36.864-28.71296c17.14176-0.43008 32.99328 8.99072 39.424 24.86272l71.168 170.5984h100.74112c22.28224 0 40.71424 18.41152 40.71424 40.71424s-18.00192 40.71424-40.71424 40.71424h-127.73376a41.30816 41.30816 0 0 1-37.72416-24.86272l-38.56384-91.29984-100.74112 329.60512a41.0624 41.0624 0 0 1-38.58432 28.2624z m0 0'
              p-id='21967' fill='#515151'></path>
        </svg>
      </>
  )
}

//描述
function descriptionIcon(size = 18) {
  return (
      <>
        <svg t='1678001076222' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='8907' width={size} height={size}>
          <path
              d='M770.942529 541.425287H247.172414c-23.54023 0-47.08046-17.655172-47.08046-47.080459 0-23.54023 23.54023-47.08046 47.08046-47.08046h523.770115c23.54023 0 47.08046 17.655172 47.08046 41.195402s-17.655172 52.965517-47.08046 52.965517c5.885057 0 0 0 0 0z m0 223.632184H247.172414c-23.54023 0-47.08046-17.655172-47.08046-47.08046 0-23.54023 23.54023-47.08046 47.08046-47.080459h523.770115c23.54023 0 47.08046 17.655172 47.08046 47.080459s-17.655172 47.08046-47.08046 47.08046zM200.091954 276.597701c0-23.54023 23.54023-47.08046 47.08046-47.08046h329.563218c23.54023 0 47.08046 17.655172 47.08046 41.195403s-17.655172 47.08046-41.195402 47.080459H247.172414c-23.54023 5.885057-47.08046-17.655172-47.08046-41.195402zM929.83908 0H94.16092C41.195402 0 0 41.195402 0 88.275862v841.563218c0 52.965517 41.195402 88.275862 94.16092 88.275863h835.67816c52.965517 0 94.16092-41.195402 94.16092-88.275863V88.275862C1018.114943 41.195402 976.91954 0 929.83908 0z'
              fill='#515151' p-id='8908'></path>
        </svg>
      </>
  )
}

//0 青绿
function cyanGreen(size = 18) {
  return dot(size, '#39C3A2')
}

//1 红色
function red(size = 18) {
  return dot(size, '#f44336')
}

//2 蓝色
function blue(size = 18) {
  return dot(size, '#2196f3')
}

//3 琥珀
function amber(size = 18) {
  return dot(size, '#ffc107')
}

//4 紫色
function purple(size = 18) {
  return dot(size, '#9c27b0')
}

//5 绿色
function green(size = 18) {
  return dot(size, '#4caf50')
}

//6 粉红
function pink(size = 18) {
  return dot(size, '#e91e63')
}

//7 青色
function cyan(size = 18) {
  return dot(size, '#00bcd4')
}

//8 棕色
function brown(size = 18) {
  return dot(size, '#795548')
}

//9 青柠
function lime(size = 18) {
  return dot(size, '#cddc39')
}

//10 靛青
function indigo(size = 18) {
  return dot(size, '#3f51b5')
}

function dot(size = 18, color) {
  return (
      <>
        <svg t='1677688815453' className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='4612' width={size} height={size}>
          <path
              d='M514.048 128q79.872 0 149.504 30.208t121.856 82.432 82.432 122.368 30.208 150.016q0 78.848-30.208 148.48t-82.432 121.856-121.856 82.432-149.504 30.208-149.504-30.208-121.856-82.432-82.432-121.856-30.208-148.48q0-79.872 30.208-150.016t82.432-122.368 121.856-82.432 149.504-30.208z'
              p-id='4613' fill={color}></path>
        </svg>
      </>
  )
}

export {fileIcon, cyanGreen, red, blue, amber, purple, pink, green, indigo, lime, cyan, brown, findColor, activityIcon, descriptionIcon, markIcon, listIcon, delIcon, subIcon, timeIcon, remindIcon, freeIcon, repeatIcon}