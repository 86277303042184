import { Encrypt, Decrypt } from "@utils/aes";

const setStorage = (key, data) => {
  let date = Encrypt(data);
  return window.localStorage.setItem(key, date);
};

const getStorage = (key) => {
  const storage = window.localStorage.getItem(key);
  if (storage) {
    const data = Decrypt(storage);
    if (data) {
      return JSON.parse(data);
    }
  }
  return null;
};

const removeStorage = (key) => {
  return window.localStorage.removeItem(key);
};

export { setStorage, getStorage, removeStorage };
