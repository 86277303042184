import Dexie from "dexie";
import { isObject } from "@utils/common";

const db = new Dexie("wegoalDB");
const folderFields = "Id,Id_Local,Mid,UserId,Name,FId,FId_Local,CreateTime,Seq";

const projectFields =
  "Id,Id_Local,Mid,FId,FId_Local,UserId,Type,SeqType,pClass,Color,FolderId,FolderId_Local,ContactId,Access,Name,Description,Status,StartTime,DueTime,DurationTime,UpdateTime,Review,ReviewRate,ReviewStartTime,ReviewEndTime,CompleteTime,ifAuto,CreateTime,Seq,FileFlag,FcFlag,Name_Seg,AccessPermission,TimeZone,Background,Display,Display2,Display3,DisplayDone,DisplayDetail,DisplayDash,DisplayStatus,DisplayGroup,Sort,ViewMode,CalendarAccount,Configure1,Configure2";

const contextFields =
  "Id,Id_Local,UserId,Mid,Type,FId,FId_Local,ContextName,Status,LocationX,LocationY,Description,ifFriend,FriendId,LinkId,GroupId,Sort,CreateTime,Icon,ifDisplay,Seq,FcFlag,Display3";

const actionFields =
  "Id,Id_Local,UserId,Mid,Type,SeqType,Flag,FolderId,FolderId_Local,ProjectId,ProjectId_Local,ContextId,ContextId_Local,ContactId,ContactId_Tag,PerspectiveId,Name,Description,Pic_Flag,Attatch_Flag,Status,StartTime,NextStartTime,LunarFlag,DueTime,NextDueTime,DurationTime,Cycle,RRULE,Repeat_every,Repeat_week,Repeat_Duetime,Repeat_num,NextCount,Repeat_from,Flag_Cal,LocationX,LocationY,LocationName,UpdateTime,Review,CompleteTime,CompleteUserId,CreateTime,Remind,RemindData,RemindTime,Seq,Seq_Schedule,Cflag,FcFlag,Name_Seg,Weather_City,BookId,BookPage,BookProgress,ReadLevel,Tomato,ShareCreateUserId,Busy,Timezone,SetTop,Fid,Fid_Local";

const perspectiveFields =
  "Id,Id_Local,Type,Mid,UserId,Name,Mode,FocusId,FocusContextId,FocusFolderId,ProjectLogic,ContextLogic,Filter,GroupId,Sort,Availability,Status,Day,StartTime,DueTime,EstimatedTime,Display,CreateTime,Cycle,Review,FromAction,ActionId,Attatch,Contact,Icon,Seq,ifEdit,ifActive";

const actionSubFields =
  "Id,Id_Local,Name,ActionId,CompleteTime,CompleteUserId,CreateTime,Description,DueTime,DurationTime,Seq,ShareCreateUserId,StartTime,Status,UserId";

const noteFields = "Id,ActionId,UserId,JsonText,Markdown,Html";

db.version(11).stores({
  folder: "Id",
  project: "Id",
  context: "Id",
  action: "Id",
  actionSub: "Id",
  perspective: "Id",
  note: "Id"
});

export function clearAll() {
  db.folder.clear();
  db.project.clear();
  db.context.clear();
  db.action.clear();
  db.actionSub.clear();
  db.perspective.clear();
  db.note.clear();
}

export function addFolder(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => folderFields.split(",").includes(k))));
      db.folder.bulkPut(res);
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => folderFields.split(",").includes(k)));
      db.folder.put(check);
    }
  }
}

export function updateFolder(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => actionFields.split(",").includes(k))));
      res.forEach((item) => {
        db.folder.update(item.Id, item);
      });
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => actionFields.split(",").includes(k)));
      db.folder.update(check.Id, check);
    }
  }
}

export function delFolder(Id) {
  db.folder.where("Id").equals(Id).delete();
}

export async function getFolder() {
  return await db.folder.toArray();
}

export async function getOneFolder(Id) {
  return db.folder.where("Id").equals(Id).first();
}

export function addProject(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => projectFields.split(",").includes(k))));
      db.project.bulkPut(res);
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => projectFields.split(",").includes(k)));
      db.project.put(check);
    }
  }
}

export function updateProject(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => actionFields.split(",").includes(k))));
      res.forEach((item) => {
        db.project.update(item.Id, item);
      });
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => actionFields.split(",").includes(k)));
      db.project.update(check.Id, check);
    }
  }
}

export function delProject(Id) {
  db.project.where("Id").equals(Id).delete();
}

export async function getProject() {
  return await db.project.toArray();
}

export async function getOneProject(Id) {
  return db.project.where("Id").equals(Id).first();
}

export function addContext(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => contextFields.split(",").includes(k))));
      db.context.bulkPut(res);
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => contextFields.split(",").includes(k)));
      db.context.put(check);
    }
  }
}

export function updateContext(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => actionFields.split(",").includes(k))));
      res.forEach((item) => {
        db.context.update(item.Id, item);
      });
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => actionFields.split(",").includes(k)));
      db.context.update(check.Id, check);
    }
  }
}

export function delContext(Id) {
  db.context.where("Id").equals(Id).delete();
}

export async function getContext() {
  return await db.context.toArray();
}

export async function getOneContext(Id) {
  return db.context.where("Id").equals(Id).first();
}

export function addAction(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => actionFields.split(",").includes(k))));
      db.action.bulkPut(res);
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => actionFields.split(",").includes(k)));
      db.action.put(check);
    }
  }
}

export function updateAction(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => actionFields.split(",").includes(k))));
      res.forEach((item) => {
        db.action.update(item.Id, item);
      });
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => actionFields.split(",").includes(k)));
      db.action.update(check.Id, check);
    }
  }
}

export function delAction(Id) {
  db.action.where("Id").equals(Id).delete();
}

export async function getAction() {
  return await db.action.toArray();
}

export async function getOneAction(Id) {
  return db.action.where("Id").equals(Id).first();
}

export function addActionSub(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => actionSubFields.split(",").includes(k))));
      db.actionSub.bulkPut(res);
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => actionSubFields.split(",").includes(k)));
      db.actionSub.put(check);
    }
  }
}

export function updateActionSub(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => actionFields.split(",").includes(k))));
      res.forEach((item) => {
        db.actionSub.update(item.Id, item);
      });
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => actionFields.split(",").includes(k)));
      db.actionSub.update(check.Id, check);
    }
  }
}

export function delActionSub(Id) {
  db.actionSub.where("Id").equals(Id).delete();
}

export async function getActionSub() {
  return await db.actionSub.toArray();
}

export async function getOneActionSub(Id) {
  return db.actionSub.where("Id").equals(Id).first();
}

export function addPerspective(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => perspectiveFields.split(",").includes(k))));
      db.perspective.bulkPut(res);
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => perspectiveFields.split(",").includes(k)));
      db.perspective.put(check);
    }
  }
}

export function updatePerspective(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => actionFields.split(",").includes(k))));
      res.forEach((item) => {
        db.perspective.update(item.Id, item);
      });
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => actionFields.split(",").includes(k)));
      db.perspective.update(check.Id, check);
    }
  }
}

export async function getPerspective(UserId) {
  return db.perspective.where("UserId").equals(UserId).reverse().sortBy("Id");
}

export function addNote(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => noteFields.split(",").includes(k))));
      db.note.bulkPut(res);
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => noteFields.split(",").includes(k)));
      db.note.put(check);
    }
  }
}

export function updateNote(data) {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      const res = data.map((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => noteFields.split(",").includes(k))));
      res.forEach((item) => {
        db.note.update(item.Id, item);
      });
    } else if (isObject(data)) {
      const check = Object.fromEntries(Object.entries(data).filter(([k, v]) => noteFields.split(",").includes(k)));
      db.note.update(check.Id, check);
    }
  }
}

export function delNote(Id) {
  db.note.where("ActionId").equals(Id).delete();
}

export async function getNote() {
  return db.note.toArray();
}

export async function getOneNote(Id) {
  return db.note.where("Id").equals(Id).first();
}
