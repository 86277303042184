import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuid } from "uuid";
import { addAction, delAction, getAction, getOneAction } from "@utils/db";
import { saveAction } from "@utils/api";
import { sort } from "@utils/sort";
import dayjs from "dayjs";
import { randomNum } from "@/utils";
import { LayoutDashboard, Inbox, Flag, CalendarCheck, BookOpen } from "lucide-react";

class ActionStore {
  allData = [];
  editId = null;
  editData = null;
  tableTitle = "所有";
  quickAddShow = false;
  quickDate = null;
  quickName = "";
  quickProject = 0;
  menu = [];
  group = [];
  groupType = "all";
  groupOtherId = "";
  allType = "none";
  allTypeOptions = [
    { key: "none", label: "按完成" },
    { key: "dueTime", label: "按截止时间" },
    { key: "startTime", label: "按开始时间" },
    { key: "project", label: "按分组" },
    { key: "context", label: "按标签" }
  ];
  count = { all: 0, gather: 0, finish: 0, flag: 0 };
  defaultTimeObject = {
    StartTime: dayjs().startOf("hour").add(1, "hour"),
    StartTimeM: dayjs().startOf("hour").add(1, "hour").minute(30),
    DueTime: dayjs().startOf("hour").add(2, "hour"),
    DueTimeM: dayjs().startOf("hour").add(2, "hour"),
    DurationTime: 30
  };
  timeObject = null;
  remindTimeOption = [
    { label: "不提醒", key: 0 },
    { label: "提前5分钟", key: 300 },
    {
      label: "提前15分钟",
      key: 900
    },
    { label: "提前30分钟", key: 1800 },
    { label: "提前1小时", key: 3600 }
  ];
  busyOptions = [
    { label: "空闲", key: 0 },
    { label: "忙碌", key: 1 }
  ];
  repeatOptions = [];
  durationTimeOptions = [
    { key: 0, label: "0分钟" },
    { key: 15, label: "15分钟" },
    { key: 30, label: "30分钟" },
    { key: 45, label: "45分钟" },
    { key: 60, label: "60分钟" },
    { key: 90, label: "90分钟" },
    { key: 120, label: "120分钟" }
  ];
  arr = {
    Id: 0,
    Id_Local: 0,
    UserId: 0,
    Mid: 0,
    Type: 0,
    SeqType: 0,
    Flag: 0,
    FolderId: 0,
    FolderId_Local: 0,
    ProjectId: 0,
    ProjectId_Local: 0,
    ContextId: "",
    ContextId_Local: "",
    ContactId: 0,
    ContactId_Tag: "",
    PerspectiveId: 0,
    Name: "",
    Description: "",
    Pic_Flag: 0,
    Attatch_Flag: 0,
    Status: 0,
    StartTime: 0,
    NextStartTime: 0,
    LunarFlag: 0,
    DueTime: 0,
    NextDueTime: 0,
    DurationTime: 0,
    Cycle: "",
    RRULE: "",
    Repeat_every: 0,
    Repeat_week: 0,
    Repeat_Duetime: 0,
    Repeat_num: 0,
    NextCount: 0,
    Repeat_from: 0,
    Flag_Cal: 0,
    LocationX: "",
    LocationY: "",
    LocationName: "",
    UpdateTime: 0,
    Review: 0,
    CompleteTime: 0,
    CompleteUserId: null,
    CreateTime: 0,
    Remind: "",
    RemindData: "",
    RemindTime: 0,
    Seq: 0,
    Seq_Schedule: 0,
    Cflag: 0,
    FcFlag: 0,
    Name_Seg: null,
    Weather_City: 0,
    BookId: "",
    BookPage: 0,
    BookProgress: 0,
    ReadLevel: 0,
    Tomato: 0,
    ShareCreateUserId: 0,
    Busy: 0,
    Timezone: "",
    SetTop: 0
  };
  saveData = {
    Op: 1,
    ActionId: 0,
    BelongUserId: 0,
    BookId: 0,
    BookPage: 0,
    BookProgress: 0,
    Busy: 0,
    CalId: 0,
    Cflag: 0,
    CompleteTime: 0,
    ContactId: "",
    ContactId_old: "",
    ContextId: "",
    ContextId_Local: "",
    CreateTime: 0,
    Cycle: "",
    Cycle_repeat: "",
    Description: "",
    DueTime: 0,
    DurationTime: 0,
    EntrustId: "",
    EntrustId_Local: "",
    FcFlag: 0,
    Fid: 0,
    Fid_Local: 0,
    Flag: 0,
    Flag_Cal: 0,
    FolderId: 0,
    FolderId_Local: 0,
    Former_DueTime: 0,
    Former_StartTime: 0,
    FromAction: 0,
    Id: 0,
    Id_Local: 0,
    LocationName: "",
    LocationX: "",
    LocationY: "",
    LunarFlag: 0,
    Mid: 0,
    ModifyAll: 0,
    Name: "",
    Name_Seg: "",
    NextCount: 0,
    NextDueTime: 0,
    NextStartTime: 0,
    PerspectiveId: 0,
    Pic_Flag: 0,
    ProjectContactId: "",
    ProjectContactId_old: "",
    ProjectId: 0,
    ProjectId_Local: 0,
    RRULE: "",
    ReadLevel: 0,
    Remind: 0,
    RemindTime: 0,
    RepeatNo: 0,
    Repeat_Duetime: 0,
    Repeat_every: 0,
    Repeat_from: 0,
    Repeat_num: 0,
    Repeat_week: "",
    Review: 0,
    Seq: 0,
    SeqType: 0,
    StartTime: 0,
    Status: 0,
    Tomato: 0,
    Type: 0,
    UpdateTime: 0,
    UserId: 0,
    UserId_org: 0,
    Weather_City: 0,
    createTimeLocal: 0,
    dayTime: 0,
    ifAuto: 0,
    isLastOne: 0
  };

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  allCount() {
    this.count.all = this.allData.filter((item) => {
      return item.Status !== 4;
    }).length;
  }

  gatherCount() {
    this.count.gather = this.allData.filter((item) => {
      return item.Status === 1;
    }).length;
  }

  finishCount() {
    this.count.finish = this.allData.filter((item) => {
      return item.Status === 9;
    }).length;
  }

  flagCount() {
    this.count.flag = this.allData.filter((item) => {
      return item.Flag === 1;
    }).length;
  }

  noteCount() {
    this.rootStore.noteStore.allCount = this.rootStore.noteStore.allData.length;
  }

  quickAddShowSwitch(type) {
    this.quickAddShow = type;
  }

  updateAllData = async (arr = null) => {
    let data = [];
    if (arr) {
      data = arr;
    } else {
      data = await getAction().then((res) => {
        return res;
      });
    }
    data = data.filter((item) => {
      return item.Mid === 0 && item.Type !== 3 && item.UserId === this.rootStore.loginStore.userinfo.UserId;
    });
    runInAction(() => {
      this.allData = data;
    });
  };

  updateMenu() {
    this.allCount();
    this.flagCount();
    this.gatherCount();
    this.finishCount();
    this.noteCount();
  }

  getMenu() {
    return [
      {
        id: uuid(),
        type: "all",
        text: "全部",
        icon: <LayoutDashboard size={18} />,
        number: this.count.all
      },
      {
        id: uuid(),
        type: "gather",
        text: "收集箱",
        icon: <Inbox size={18} />,
        number: this.count.gather
      },
      {
        id: uuid(),
        type: "flag",
        text: "旗标",
        icon: <Flag size={18} />,
        number: this.count.flag
      },
      {
        id: uuid(),
        type: "finish",
        text: "已完成",
        icon: <CalendarCheck size={18} />,
        number: this.count.finish
      },
      {
        id: uuid(),
        type: "note",
        text: "笔记",
        icon: <BookOpen size={18} />,
        number: this.rootStore.noteStore.allCount
      }
    ];
  }

  getAll() {
    const all = sort(
      this.allData.filter((item) => {
        return item.Status !== 4;
      }),
      "CreateTime"
    );
    if (this.allType === "none") {
      let un = all.filter((item) => {
        return item.Status !== 9;
      });
      let finish = all.filter((item) => {
        return item.Status === 9;
      });
      this.group = [
        {
          title: "未完成",
          list: sort(un, "UpdateTime")
        },
        {
          title: "已完成",
          list: sort(finish, "UpdateTime")
        }
      ];
    }
    if (this.allType === "dueTime") {
      let group = {
        未设置截止日期: []
      };
      all.forEach((item) => {
        if (item.DueTime === 0) {
          group.未设置截止日期.push(item);
        } else {
          const day = dayjs.unix(item.DueTime).startOf("date").format("YYYY-MM-DD");
          if (group.hasOwnProperty(day) === false) {
            group[day] = [];
          }
          group[day].push(item);
        }
      });
      let newGroup = [];
      for (let key in group) {
        newGroup.push({
          title: key,
          list: group[key]
        });
      }
      this.group = sort(newGroup, "title");
    }
    if (this.allType === "startTime") {
      let group = {
        未设置开始日期: []
      };
      all.forEach((item) => {
        if (item.StartTime === 0) {
          group.未设置开始日期.push(item);
        } else {
          const day = dayjs.unix(item.StartTime).startOf("date").format("YYYY-MM-DD");
          if (group.hasOwnProperty(day) === false) {
            group[day] = [];
          }
          group[day].push(item);
        }
      });
      let newGroup = [];
      for (let key in group) {
        newGroup.push({
          title: key,
          list: group[key]
        });
      }
      this.group = sort(newGroup, "title");
    }
    if (this.allType === "project") {
      let group = [{ title: "未设置分组", key: 0, list: [] }];
      this.rootStore.projectStore.actionOptions.forEach((item) => {
        group.push({ title: item.label, key: item.value, list: [] });
      });
      group.forEach((one) => {
        all.forEach((item) => {
          if (item.ProjectId === one.key) {
            one.list.push(item);
          }
        });
      });
      this.group = group;
    }
    if (this.allType === "context") {
      let group = [{ title: "未设置标签", key: 0, list: [] }];
      this.rootStore.contextStore.options.forEach((item) => {
        group.push({ title: item.label, key: item.value, list: [] });
      });
      group.forEach((one) => {
        all.forEach((item) => {
          if (item.ContextId.indexOf(one.key) !== -1) {
            one.list.push(item);
          }
        });
      });
      this.group = group;
    }
  }

  getList() {
    let data = [];
    switch (this.groupType) {
      case "all":
        this.tableTitle = "所有";
        return this.getAll();
      case "flag":
        data = this.allData.filter((item) => {
          return item.Status !== 4 && item.Flag === 1;
        });
        this.tableTitle = "旗标";
        break;
      case "gather":
        data = this.allData.filter((item) => {
          return item.Status === 1;
        });
        this.tableTitle = "收集箱";
        break;
      case "finish":
        data = this.allData.filter((item) => {
          return item.Status === 9;
        });
        this.tableTitle = "已完成";
        break;
      case "project":
        data = this.allData.filter((item) => {
          return item.Status !== 4 && item.ProjectId === this.groupOtherId;
        });
        break;
      case "context":
        data = this.allData.filter((item) => {
          return item.Status !== 4 && item.ContextId.indexOf(this.groupOtherId) !== -1;
        });
        break;
      default:
        this.tableTitle = "所有";
        return this.getAll();
    }
    let un = data.filter((item) => {
      return item.Status !== 9;
    });
    let finish = data.filter((item) => {
      return item.Status === 9;
    });
    this.group = [
      {
        title: "未完成",
        list: sort(un, "UpdateTime")
      },
      {
        title: "已完成",
        list: sort(finish, "UpdateTime")
      }
    ];
  }

  doComplete = async (item) => {
    if (item.Id === 0) {
      return { code: 500, msg: "操作失败，请稍候重试。" };
    }
    const params = {
      Id: item.Id,
      UserId: item.UserId,
      Id_Local: item.Id_Local,
      Status: 9,
      Op: 4
    };
    let newOne = { ...item };
    newOne.Status = params.Status;
    addAction(newOne);
    await this.updateAllData();
    return await saveAction(params)
      .then((res) => {
        if (res.code !== 0) {
          addAction(item);
          this.updateAllData();
        }
        return res;
      })
      .catch((e) => {
        return { code: 500, msg: "操作失败，请稍候重试。" };
      });
  };

  doDel = async (item) => {
    if (item.Id === 0) {
      return { code: 500, msg: "操作失败，请稍候重试。" };
    }
    const params = {
      Id: item.Id,
      UserId: item.UserId,
      Id_Local: item.Id_Local,
      Op: 3,
      Cycle: item.Cycle
    };
    delAction(item.Id);
    await this.updateAllData();

    return await saveAction(params)
      .then((res) => {
        if (res.code !== 0) {
          addAction(item);
          this.updateAllData();
        }
        return res;
      })
      .catch((e) => {
        addAction(item);
        this.updateAllData();
        return { code: 500, msg: "操作失败，请稍候重试。" };
      });
  };

  saveAction = async (form) => {
    let params = { ...this.saveData };
    let old = null;
    params.Op = 1;
    params.UserId = this.rootStore.loginStore.userinfo.UserId;
    for (let key in form) {
      if (form[key] !== undefined) {
        params[key] = form[key];
      }
    }

    if (form.Id > 0) {
      params.Id = form.Id;
      params.Op = 2;
      params.UpdateTime = dayjs().unix();
      await getOneAction(form.Id).then((res) => {
        old = res;
      });
    } else {
      params.CreateTime = dayjs().unix();
      params.Id_Local = randomNum(9);
    }
    let newOne = { ...this.arr };
    for (let key in newOne) {
      if (params[key] !== undefined) {
        newOne[key] = params[key];
      }
    }
    addAction(newOne);
    await this.updateAllData();
    return await saveAction(params)
      .then((res) => {
        if (res.code === 0) {
          if (form.Id > 0) {
            return res;
          }
          newOne.Id = Array.isArray(res.data.data) ? res.data.data[0].Id : res.data.data.Id;
          addAction(newOne);
        }
        if (form.Id > 0) {
          addAction(old);
        } else {
          delAction(0);
        }
        this.updateAllData();
        return res;
      })
      .catch((e) => {
        if (form.Id > 0) {
          addAction(old);
        } else {
          delAction(0);
        }
        this.updateAllData();
        return { code: 500, msg: "操作失败，请稍候重试。" };
      });
  };
}

export default ActionStore;
