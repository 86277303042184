import {useEffect} from "react";
import {useNavigate} from 'react-router-dom'
import {removeStorage} from '@/utils'

function Logout() {
  const navigate = useNavigate()
  useEffect(() => {
    removeStorage('userinfo')
    alert('已退出登录!')
    setTimeout(function() {
      navigate('/login', {replace: true})
    },2000);
  }, []);
}

export default Logout