import { observer } from "mobx-react-lite";
import { Popover, message } from "antd";
import { useStore } from "@/store";
import { useNavigate } from "react-router-dom";
import { removeStorage } from "@/utils";
import { clearAll } from "@utils/db";
import { Settings } from "lucide-react";

const MainLeftHead = observer(function () {
  const { mainStore, loginStore } = useStore();
  const navigate = useNavigate();
  const logout = () => {
    removeStorage("userinfo");
    clearAll();
    mainStore.clearIntervalAll();
    message.success("退出成功", 2, () => {
      navigate("/login", { replace: true });
    });
  };

  return (
    <div className="zc-tx">
      <div>
        <img className="zc-tx-tx l" alt="" src={loginStore.userinfo.avatar ? loginStore.userinfo.avatar : require("../assets/images/zc-tx.jpg")} />
        <div className="huidMy">
          <p className="zc-tx-nm l">{loginStore.userinfo.name}</p>
        </div>
      </div>
      <Popover
        placement="bottom"
        content={
          <div>
            <p className="bai-xia-xh" onClick={logout}>
              退出登录
            </p>
          </div>
        }
        trigger="click"
      >
        <Settings size={18} className="float-right " />
      </Popover>
      {/*<img className='zc-tx-ss r' alt='' src={require("../assets/images/baseline_search_black_28dp.png")} />*/}

      {/*<input className="sousou" type="text" name="" id="" value="" placeholder="搜索"/>*/}
    </div>
  );
});
export { MainLeftHead };
