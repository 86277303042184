import { Extension } from "@tiptap/core";

import { NodeSelection, Plugin } from "@tiptap/pm/state";
import { __serializeForClipboard } from "@tiptap/pm/view";

function absoluteRect(node) {
  const data = node.getBoundingClientRect();
  return {
    top: data.top,
    left: data.left,
    width: data.width
  };
}

function nodeDOMAtCoords(coords) {
  return document
    .elementsFromPoint(coords.x, coords.y)
    .find((elem) => elem.parentElement?.matches?.(".ProseMirror") || elem.matches(["li", "p:not(:first-child)", "pre", "blockquote", "h1, h2, h3"].join(", ")));
}

function nodePosAtDOM(node, view) {
  const boundingRect = node.getBoundingClientRect();

  return view.posAtCoords({
    left: boundingRect.left + 1,
    top: boundingRect.top + 1
  })?.inside;
}

function DragHandle(options) {
  function handleDragStart(event, view) {
    view.focus();
    if (!event.dataTransfer) return;

    const node = nodeDOMAtCoords({
      x: event.clientX + 50 + options.suspensionWidth,
      y: event.clientY
    });

    if (!(node instanceof Element)) return;

    const nodePos = nodePosAtDOM(node, view);
    if (nodePos == null || nodePos < 0) return;

    view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, nodePos)));

    const slice = view.state.selection.content();
    const { dom, text } = __serializeForClipboard(view, slice);

    event.dataTransfer.clearData();
    event.dataTransfer.setData("text/html", dom.innerHTML);
    event.dataTransfer.setData("text/plain", text);
    event.dataTransfer.effectAllowed = "copyMove";

    event.dataTransfer.setDragImage(node, 0, 0);

    view.dragging = { slice, move: event.ctrlKey };
  }

  function handleClick(event, view) {
    view.focus();
    view.dom.classList.remove("dragging");

    const node = nodeDOMAtCoords({
      x: event.clientX + 50 + options.suspensionWidth,
      y: event.clientY
    });

    if (!(node instanceof Element)) return;

    const nodePos = nodePosAtDOM(node, view);
    if (!nodePos) return;

    view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, nodePos)));
  }

  let suspensionElement = null;
  let dragHandleElement = null;
  let commandsHandleElement = null;

  function hideSuspension() {
    if (suspensionElement) {
      suspensionElement.classList.add("hidden");
    }
  }

  function showSuspension() {
    if (suspensionElement) {
      suspensionElement.classList.remove("hidden");
    }
  }

  return new Plugin({
    view: (view) => {
      suspensionElement = document.createElement("div");
      suspensionElement.classList.add("handleSuspension");

      commandsHandleElement = document.createElement("div");
      commandsHandleElement.classList.add("handleCommands");

      dragHandleElement = document.createElement("div");
      dragHandleElement.draggable = true;
      dragHandleElement.dataset.dragHandle = "";
      dragHandleElement.classList.add("handleDrag");
      dragHandleElement.addEventListener("dragstart", (e) => {
        handleDragStart(e, view);
      });
      dragHandleElement.addEventListener("click", (e) => {
        handleClick(e, view);
      });
      // suspensionElement.appendChild(commandsHandleElement);
      suspensionElement.appendChild(dragHandleElement);
      hideSuspension();

      view?.dom?.parentElement?.appendChild(suspensionElement);

      return {
        destroy: () => {
          suspensionElement?.remove?.();
          suspensionElement = null;
        }
      };
    },
    props: {
      handleDOMEvents: {
        mousemove: (view, event) => {
          if (!view.editable) {
            return;
          }

          const node = nodeDOMAtCoords({
            x: event.clientX + 50 + options.suspensionWidth,
            y: event.clientY
          });
          if (!(node instanceof Element) || node.matches("ul, ol, hr")) {
            hideSuspension();
            return;
          }

          const compStyle = window.getComputedStyle(node);
          const lineHeight = parseInt(compStyle.lineHeight, 10);
          const paddingTop = parseInt(compStyle.paddingTop, 10);

          const rect = absoluteRect(node);

          rect.top += (lineHeight - 24) / 2;
          rect.top += paddingTop;
          // Li markers
          if (node.matches("ul:not([data-type=taskList]) li, ol li")) {
            rect.left -= options.suspensionWidth;
          }
          rect.width = options.suspensionWidth / 2;

          if (!suspensionElement) return;

          suspensionElement.style.left = `${rect.left - rect.width}px`;
          suspensionElement.style.top = `${rect.top}px`;
          showSuspension();
        },
        keydown: () => {
          hideSuspension();
        },
        mousewheel: () => {
          hideSuspension();
        },
        // dragging class is used for CSS
        dragstart: (view) => {
          view.dom.classList.add("dragging");
        },
        drop: (view) => {
          view.dom.classList.remove("dragging");
        },
        dragend: (view) => {
          view.dom.classList.remove("dragging");
        }
      }
    }
  });
}

const DragAndDrop = Extension.create({
  name: "dragAndDrop",
  addProseMirrorPlugins() {
    return [
      DragHandle({
        suspensionWidth: 40
      })
    ];
  }
});

export default DragAndDrop;
