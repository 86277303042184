import {makeAutoObservable, runInAction} from 'mobx'
import {getStorage} from '@/utils'
import {addContext, delContext, getContext, getOneContext} from '@utils/db'
import {markIcon} from '@utils/icon'
import {saveContext} from '@utils/api'
import dayjs from 'dayjs'
import {sort} from '@utils/sort'

class ContextStore {
  allData = []
  arr = {
    "Id"    : 0, "Id_Local": 0, "UserId": 0, "Mid": 0, "Type": 0, "FId": 0, "FId_Local": 0, "ContextName": "", "Status": 0, "LocationX": null, "LocationY": null, "Description": null, "ifFriend": 0, "FriendId": 0,
    "LinkId": 0, "GroupId": 0, "Sort": 0, "CreateTime": 0, "Icon": 0, "ifDisplay": 0, "Seq": 0, "FcFlag": 0, "Display3": 0
  }
  saveData = {
    'Op': 1, 'UserId': 0, 'Id_Local': 0, 'Type': 0, 'FId': 0, 'FId_Local': 0, 'ContextName': '', 'Status': 0, 'LocationX': '', 'LocationY': '', 'Description': '', 'Icon': 0, 'ifDisplay': 0
  }
  treeData = []
  options = []
  showModel = false
  ContextName = ''
  modelText = ''

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateAllData = async(arr = null) => {
    let data = []
    if (arr) {
      data = arr
    } else {
      data = await getContext().then((res) => {
        return res
      })
    }
    runInAction(() => {
      this.allData = sort(data, 'Id', 'asc')
      this.updateTreeData()
      this.updateOptions()
    })
  }

  switchModel = () => {
    this.showModel = !this.showModel
  }

  setContextName = (value) => {
    this.ContextName = value
  }

  setModelText = (value) => {
    this.modelText = value
  }

  updateTreeData() {
    this.treeData = this.allData.map(item => {
      return {
        'title'   : item.ContextName,
        'key'     : item.Id,
        'icon'    : markIcon(15),
        'children': null
      }
    })
  }

  updateOptions() {
    this.options = this.allData.map(item => {
      return {
        label: item.ContextName,
        value: item.Id
      }
    })
  }

  delContext = async(Id) => {
    const userinfo = getStorage('userinfo')
    const one = await getOneContext(Id)
    const params = {'Op': 3, 'Id': Id, 'Id_Local': one.Id_Local, 'UserId': userinfo.UserId}
    await saveContext(params).then((res) => {
      if (res.code === 0) {
        delContext(Id)
      }
      return res
    })
  }

  saveContext = async(form) => {
    const userinfo = getStorage('userinfo')
    let params = {...this.saveData}
    params.Op = 1
    params.ContextName = form.ContextName
    params.UserId = userinfo.UserId
    if (form.Id > 0) {
      params.Id = form.Id
      params.Op = 2
      params.UpdateTime = dayjs().unix()
    } else {
      params.CreateTime = dayjs().unix()
    }
    return await saveContext(params).then((res) => {
      if (res.code === 0) {
        let newOne = {...this.arr}
        for (let key in newOne) {
          if (params[key] !== undefined) {
            newOne[key] = params[key]
          }
        }
        newOne.Id = res.data.data.Id
        addContext(newOne)
      }
      return res
    })
  }

  handBadIds(ids) {
    let newArr = []
    if (ids !== '' && ids !== 0) {
      let arr = ids.split(',')
      arr.forEach((item) => {
        const id = parseInt(item)
        this.allData.forEach((a) => {
          if (a.Id === id) {
            newArr.push(id)
          }
        })
      })
    }
    return newArr
  }

  tableShow(ids) {
    let newArr = []
    if (ids !== '' && ids !== 0) {
      let arr = ids.split(',')
      arr.forEach((item) => {
        let id = parseInt(item)
        this.allData.forEach((a) => {
          if (a.Id === id) {
            newArr.push(a.ContextName)
          }
        })
      })
    }
    return (newArr.length > 0 ? newArr.join(' ') : '')
  }
}

export default ContextStore