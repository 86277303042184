import {makeAutoObservable} from 'mobx'
import { setStorage, getStorage} from '@/utils'
import {login} from '@utils/api'
class LoginStore {
  userinfo = getStorage('userinfo') || {
    'token': '',
    "UserCount": "",
    "UserId": 0,
    "name": "",
    "EnterpriseId": 0,
    "avatar": null,
    "Storage": 0
  }

  constructor() {
    makeAutoObservable(this)
  }

  login = async({userCount, password}) => {
    const userinfo = getStorage('userinfo')
    if (userinfo && userinfo['token'] !== '') {
      return {code: 0, userinfo: this.userinfo, msg: '您已登陆'}
    }
    return await login({userCount: userCount, password: password}).then((res) => {
      if (res.code === 0) {
        setStorage('userinfo', res.data[0])
        this.userinfo = res.data[0]
      }
      return res
    })

    // return {code: code, userinfo: this.userinfo, msg: msg}
  }
}

export default LoginStore