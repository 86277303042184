import { makeAutoObservable, runInAction } from "mobx";
import { addFolder, delFolder, getFolder, getOneFolder } from "@utils/db";
import { saveFolder } from "@utils/api";
import dayjs from "dayjs";

class FolderStore {
  showModel = false;
  allData = null;

  arr = {
    Id: 0,
    Id_Local: 0,
    Mid: 0,
    UserId: 0,
    Name: "",
    FId: 0,
    FId_Local: 0,
    CreateTime: "",
    Seq: 0
  };
  saveData = {
    Op: 1,
    UserId: 0,
    Id_Local: 0,
    FId: 0,
    FId_Local: 0,
    Name: "",
    Seq: 0
  };

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  switchModel = () => {
    this.showModel = !this.showModel;
  };

  defaultArr = () => {
    this.arr = {
      Id: 0,
      Id_Local: 0,
      Mid: 0,
      UserId: 0,
      Name: "",
      FId: 0,
      FId_Local: 0,
      CreateTime: "",
      Seq: 0
    };
  };

  updateAllData = async (arr = null) => {
    let data = [];
    if (arr) {
      data = arr;
    } else {
      data = await getFolder().then((res) => {
        return res;
      });
    }
    runInAction(() => {
      this.allData = data;
      this.rootStore.projectStore.updateAllData(this.rootStore.projectStore.allData);
    });
  };

  delFolder = async (Id) => {
    const one = await getOneFolder(Id);
    const params = {
      Op: 3,
      Id: Id,
      Id_Local: one.Id_Local,
      DelFlag: 1,
      UserId: this.rootStore.loginStore.userinfo.UserId
    };
    await saveFolder(params).then((res) => {
      if (res.code === 0) {
        delFolder(Id);
      }
      this.updateAllData();
      return res;
    });
  };

  saveFolder = async (form) => {
    let params = { ...this.saveData };
    params.op = 1;
    params.Name = form.Name;
    params.UserId = this.rootStore.loginStore.userinfo.UserId;
    if (form.Id > 0) {
      params.Id = form.Id;
      params.Op = 2;
    } else {
      params.CreateTime = dayjs().unix();
    }

    return await saveFolder(params)
      .then((res) => {
        if (res.code === 0) {
          let newOne = { ...this.arr };
          for (let key in newOne) {
            if (params[key] !== undefined) {
              newOne[key] = params[key];
            }
          }
          newOne.Id = res.data.data.Id;
          addFolder(newOne);
        }
        this.updateAllData();
        return res;
      })
      .catch((e) => {
        this.updateAllData();

        return { code: 500, msg: "请求失败,请稍后重试" };
      });
  };
}

export default FolderStore;
