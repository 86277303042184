export function sort(arr, key, order = 'desc') {
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (order === 'aes') {
        if (parseInt(arr[i][key]) > parseInt(arr[j][key])) {
          let temp = arr[i];
          arr[i] = arr[j];
          arr[j] = temp;
        }
      } else {
        if (parseInt(arr[i][key]) < parseInt(arr[j][key])) {
          let temp = arr[i];
          arr[i] = arr[j];
          arr[j] = temp;
        }
      }
    }
  }
  return arr
}