import { createBrowserRouter } from "react-router-dom";

import Main from "@pages/main";
import Login from "@pages/login";
import Register from "@pages/register";
import Logout from "@pages/logout";
import AppNote from "@pages/appNote";

const routers = createBrowserRouter([
  {
    path: "/",
    element: <Main />
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/register",
    element: <Register />
  },
  {
    path: "/logout",
    element: <Logout />
  },
  {
    path: "/app_note",
    element: <AppNote />
  }
]);

export default routers;
