import * as CryptoJS from "crypto-js";

const config = {
  h5: {
    key: "_p&+fxftvvuZa;ps",
    iv: "8923123467281962"
  },
  app: {
    key: "a_pXutkc616fyfxf",
    iv: "3C427D362E46467E"
  }
};

function base64_encode(string) {
  return CryptoJS.enc.Base64.stringify(string);
}

function base64_decode(string) {
  return CryptoJS.enc.Base64.parse(string).toString(CryptoJS.enc.Utf8);
}

function Encrypt(data, type = "h5") {
  const dataStr = JSON.stringify(data);
  const string = CryptoJS.enc.Utf8.parse(dataStr);
  let encrypted = CryptoJS.AES.encrypt(string, CryptoJS.enc.Utf8.parse(config[type].key), {
    iv: CryptoJS.enc.Utf8.parse(config[type].iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}

function Decrypt(data, type = "h5") {
  const dataStr = CryptoJS.enc.Base64.parse(data);
  const string = CryptoJS.enc.Base64.stringify(dataStr);
  let decrypted = CryptoJS.AES.decrypt(string, CryptoJS.enc.Utf8.parse(config[type].key), {
    iv: CryptoJS.enc.Utf8.parse(config[type].iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  try {
    const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  } catch (e) {
    return null;
  }
}

export { Encrypt, Decrypt, base64_encode, base64_decode };
