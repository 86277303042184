import Editor from "src/editor";
import { Input, message } from "antd";
import { useStore } from "@/store";
import { useEffect, useState } from "react";
import { reaction } from "mobx";

function RightNote() {
  const { noteStore } = useStore();
  const [title, setTitle] = useState("");
  const [defaultContent, setDefaultContent] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const error = (msg) => {
    messageApi.error(msg, 5);
  };
  useEffect(() => {
    reaction(
      () => noteStore.editActionInfo,
      () => {
        setTitle(noteStore.editActionInfo.Name);
      },
      { fireImmediately: true }
    );
    reaction(
      () => noteStore.editNodeInfo,
      () => {
        if (noteStore.editNodeInfo.JsonText.length > 0) {
          try {
            setDefaultContent(JSON.parse(noteStore.editNodeInfo.JsonText));
          } catch (e) {}
        } else {
          setDefaultContent(null);
        }
      },
      { fireImmediately: true }
    );
  }, []);

  const titleChange = (e) => {
    setTitle(e.currentTarget.value);
    noteStore.editActionInfo.Name = e.currentTarget.value;
  };
  const handleContent = (json, html, text) => {
    noteStore.editActionInfo.Description = text.slice(0, 100);
    noteStore.editNodeInfo.JsonText = JSON.stringify(json);
    noteStore.editNodeInfo.Html = html;
    save();
  };
  const save = () => {
    noteStore.editNote().then((res) => {
      if (res.code !== 0) {
        error(res.msg);
      }
    });
  };
  return (
    <div className="float-left">
      {contextHolder}
      <div className="mx-16 mt-14">
        <Input
          placeholder="Untitled"
          bordered={false}
          value={title.length > 0 ? title : ""}
          maxLength={50}
          size="large"
          className="text-3xl"
          onChange={titleChange}
          onBlur={save}
        />
      </div>
      <Editor onUpdate={handleContent} defaultContent={defaultContent} />
    </div>
  );
}

export default RightNote;
