import {observer} from 'mobx-react-lite'
import {useStore} from '@/store'
import {Button, Checkbox, DatePicker, Divider, Dropdown, Form, Input, message, Select, Space, TimePicker} from 'antd'
import {descriptionIcon, timeIcon, subIcon, remindIcon, listIcon, markIcon, freeIcon, repeatIcon} from '@utils/icon'
import {useEffect, useState} from 'react'
import {reaction, runInAction} from 'mobx'
import dayjs from 'dayjs'
import TextArea from 'antd/es/input/TextArea'

const Right = observer(function() {
  const {actionStore, projectStore, contextStore, actionSubStore} = useStore()
  const [messageApi, contextHolder] = message.useMessage();

  const [nameAnimation, setNameAnimation] = useState(false)
  const [childAnimation, setChildAnimation] = useState(false)
  const [remindTimeAnimation, setRemindTimeAnimation] = useState(false)
  const [projectAnimation, setProjectAnimation] = useState(false)
  const [repeatAnimation, setRepeatAnimation] = useState(false)
  const [busyAnimation, setBusyAnimation] = useState(false)

  const [actionSubName, setActionSubName] = useState('')
  const [setTime, setSetTime] = useState(false)
  const [dueTimeShow, setDueTimeShow] = useState(true)
  const [dueTimeDateShow, setDueTimeDateShow] = useState(false)
  const [durationTimeButton, setDurationTimeButton] = useState('持续时间:30分钟')
  const [remindTimeButton, setRemindTimeButton] = useState('不提醒')
  const [projectButton, setProjectButton] = useState('设置分组')
  const [repeatButton, setRepeatButton] = useState('不重复')
  const [busyButton, setBusyButton] = useState('空闲')
  const [form] = Form.useForm()
  useEffect(() => {
    runInAction(() => {
      actionStore.timeObject = actionStore.defaultTimeObject
    })
    reaction(
        () => actionStore.editId,
        () => {
          if (actionStore.editId !== null) {
            initTime()
            handleData()
            actionSubStore.getSub(actionStore.editId)
          }
        }, {fireImmediately: true}
    );

  }, [])

  const handleData = () => {
    setActionSubName('')
    setSetTime(false)
    setDueTimeShow(true)
    setDueTimeDateShow(false)
    setDurationTimeButton('持续时间:30分钟')
    setRemindTimeButton('不提醒')
    setProjectButton('设置分组')
    setRepeatButton('不重复')
    setBusyButton('空闲')

    changeProject(actionStore.editData.ProjectId, false)
    changeBusy(actionStore.editData.Busy, false)
    changeRepeatOptions(actionStore.editData.Cycle, false)
    changeRemindTime(actionStore.editData.RemindTime, false)
    form.setFieldsValue({
      Name       : actionStore.editData.Name,
      Description: actionStore.editData.Description,
      ContextId  : contextStore.handBadIds(actionStore.editData.ContextId)
    })
  }
  const handleTime = () => {
    runInAction(() => {
      actionStore.editData.StartTime = actionStore.timeObject.StartTimeM.unix()
      actionStore.editData.DueTime = actionStore.timeObject.DueTimeM.unix()
      actionStore.editData.DurationTime = actionStore.timeObject.DurationTime
    })
    save()
  }
  const initTime = () => {
    setSetTime(false)
    if (actionStore.editData.StartTime !== 0) {
      let StartTime = actionStore.editData.StartTime
      setSetTime(true)
      runInAction(() => {
        actionStore.timeObject.StartTime = dayjs.unix(StartTime)
        actionStore.timeObject.StartTimeM = actionStore.timeObject.StartTime
        actionStore.timeObject.DurationTime = actionStore.editData.DurationTime
      })

      form.setFieldsValue({
        StartTime : actionStore.timeObject.StartTime,
        StartTimeM: actionStore.timeObject.StartTimeM
      })
      setDurationTimeButton('持续时间:' + actionStore.editData.DurationTime + '分钟')
      if (actionStore.editData.DurationTime === 0) {
        setDueTimeShow(false)
      } else {
        const dueTime = actionStore.timeObject.StartTimeM.add(actionStore.timeObject.DurationTime, 'minute')
        const diffDay = actionStore.timeObject.StartTime.diff(dueTime, 'day')
        setDueTimeDateShow(diffDay !== 0)
        runInAction(() => {
          actionStore.timeObject.DueTime = dueTime
          actionStore.timeObject.DueTimeM = dueTime
        })
        form.setFieldsValue({
          DueTime : actionStore.timeObject.DueTime,
          DueTimeM: actionStore.timeObject.DueTimeM
        })
      }
    }
    handleRepeatOptions()
  }
  const changeSetTime = (boolean) => {
    if (!boolean) {
      actionStore.editData.Cycle = ''
      actionStore.editData.Repeat_week = ''
      actionStore.editData.StartTime = 0
      actionStore.editData.DueTime = 0
      actionStore.editData.DurationTime = 0
    } else {
      actionStore.editData.Cycle = ''
      actionStore.editData.Repeat_week = ''
      actionStore.editData.StartTime = dayjs().startOf('hour').add(1, 'hour').unix()
      actionStore.editData.DueTime = 0
      actionStore.editData.DurationTime = 0
      handleRepeatOptions()
    }
    initTime()
    setSetTime(boolean)
    save()
  }
  const handleRepeatOptions = () => {
    const D = actionStore.timeObject.StartTime.format('D')
    const options = [
      {
        key  : '',
        label: '不重复'
      },
      {
        key  : 'd',
        label: '每天'
      },
      {
        key  : 'w',
        label: '每周' + actionStore.timeObject.StartTime.format('dddd')
      },
      {
        key  : 'mw',
        label: '每月第' + Math.ceil(D / 7) + '个' + actionStore.timeObject.StartTime.format('dddd')
      },
      {
        key  : 'm',
        label: '每月在' + '' + actionStore.timeObject.StartTime.format('D') + '号'
      },
      {
        key  : 'y',
        label: '每年在' + '每月在' + '' + actionStore.timeObject.StartTime.format('MM月DD日')
      },
      {
        key  : 'ww',
        label: '每个工作日(周一至周五)'
      }
    ]
    runInAction(() => {
      actionStore.repeatOptions = options
    })
  }
  const changeDurationTime = (e) => {
    if (e.key === 0) {
      setDueTimeShow(false)
    } else {
      setDueTimeShow(true)
      const dueTime = actionStore.timeObject.StartTimeM.add(e.key, 'minute')
      const diffDay = actionStore.timeObject.StartTime.diff(dueTime, 'day')
      setDueTimeDateShow(diffDay !== 0)
      runInAction(() => {
        actionStore.timeObject.DueTime = dueTime
        actionStore.timeObject.DueTimeM = dueTime
      })
      form.setFieldsValue({
        DueTime : actionStore.timeObject.DueTime,
        DueTimeM: actionStore.timeObject.DueTimeM
      })
    }
    setDurationTimeButton('持续时间:' + e.key + '分钟')
    runInAction(() => {
      actionStore.timeObject.DurationTime = e.key
    })
    handleTime()
  }
  const changeDueTime = (e, type) => {
    let year
    let minute
    if (type === 1) {
      year = e.format('YYYY-MM-DD')
      minute = actionStore.timeObject.DueTimeM.format('HH:mm')
    } else {
      year = actionStore.timeObject.DueTime.format('YYYY-MM-DD')
      minute = e.format('HH:mm')
    }
    const dueTime = dayjs(year + ' ' + minute)
    const diff = dueTime.diff(actionStore.timeObject.StartTimeM, 'minute')
    if (diff > 0) {
      setDurationTimeButton('持续时间:' + diff + '分钟')
      runInAction(() => {
        actionStore.timeObject.DurationTime = diff
        actionStore.timeObject.DueTime = dueTime
        actionStore.timeObject.DueTimeM = dueTime
      })
    } else {
      form.setFieldsValue({
        DueTime : actionStore.timeObject.DueTime,
        DueTimeM: actionStore.timeObject.DueTimeM
      })
      messageApi.open({type: 'error', content: '截止时间不能小于开始时间'});
      return
    }
    handleTime()
  }
  const changeStartTime = (e, type) => {
    let year
    let minute
    if (type === 1) {
      year = e.format('YYYY-MM-DD')
      minute = actionStore.timeObject.StartTimeM.format('HH:mm')
    } else {
      year = actionStore.timeObject.StartTime.format('YYYY-MM-DD')
      minute = e.format('HH:mm')
    }

    const startTime = dayjs(year + ' ' + minute)
    const dueTime = startTime.add(actionStore.timeObject.DurationTime, 'minute')
    runInAction(() => {
      actionStore.timeObject.StartTime = startTime
      actionStore.timeObject.StartTimeM = startTime
      actionStore.timeObject.DueTime = dueTime
      actionStore.timeObject.DueTimeM = dueTime
    })

    form.setFieldsValue({
      DueTime : actionStore.timeObject.DueTime,
      DueTimeM: actionStore.timeObject.DueTimeM
    })
    handleTime()
  }
  const changeRemindTime = (e, update = true) => {
    const RemindTime = e.constructor === Object ? parseInt(e.key) : e
    actionStore.editData.RemindTime = RemindTime
    actionStore.remindTimeOption.forEach(item => {
      if (item.key === RemindTime) {
        setRemindTimeButton(item.label)
      }
    })
    if (update) save()
  }
  const changeProject = (e, update = true) => {
    const ProjectId = e.constructor === Object ? parseInt(e.key) : e
    actionStore.editData.ProjectId = ProjectId
    projectStore.menuOptions.forEach(item => {
      if (item.key === ProjectId) {
        setProjectButton(item.label)
      }
    })
    if (update) save()
  }
  const changeBusy = (e, update = true) => {
    const Busy = e.constructor === Object ? parseInt(e.key) : e
    actionStore.editData.Busy = Busy
    actionStore.busyOptions.forEach(item => {
      if (item.key === Busy) {
        setBusyButton(item.label)
      }
    })
    if (update) save()
  }
  const setAllDay = (e) => {
    if (e.target.checked) {
      runInAction(() => {
        actionStore.timeObject.DurationTime = -1
        actionStore.timeObject.DueTime = actionStore.timeObject.StartTime.endOf('date')
      })
    } else {
      setDurationTimeButton('持续时间:30分钟')
      setDueTimeShow(true)
      runInAction(() => {
        actionStore.timeObject.DurationTime = 30
        actionStore.timeObject.StartTimeM = dayjs().startOf('hour').minute(30)
        const dueTime = actionStore.timeObject.StartTimeM.add(30, 'minute')
        actionStore.timeObject.DueTime = dueTime
        actionStore.timeObject.DueTimeM = dueTime
      })
      form.setFieldsValue({
        StartTimeM: actionStore.timeObject.StartTimeM,
        DueTime   : actionStore.timeObject.DueTime,
        DueTimeM  : actionStore.timeObject.DueTimeM
      })
    }
    handleTime()
  }
  const changeRepeatOptions = (e, update = true) => {
    let Cycle = ''
    if (e !== null) {
      Cycle = e.constructor === Object ? e.key : e
    }
    actionStore.editData.Cycle = Cycle
    actionStore.editData.Repeat_week = ''
    if (Cycle === 'w' || Cycle === 'mw') {
      let week = actionStore.timeObject.StartTime.format('d')
      if (week === 0) week = 7
      actionStore.editData.Repeat_week = week
    }
    if (Cycle === 'ww') {
      actionStore.editData.Repeat_week = '1|2|3|4|5'
    }
    actionStore.repeatOptions.forEach(item => {
      if (item.key === Cycle) {
        setRepeatButton(item.label)
      }
    })
    if (update) save()
  }
  const changeDescription = (e) => {
    actionStore.editData.Description = e.target.value
  }
  const changeContext = (e) => {
    actionStore.editData.ContextId = e.join(',')
    save()
  }
  const changeName = (e) => {
    setNameAnimation(false)
    runInAction(() => {
      actionStore.editData.Name = e.target.value
    })
    save()
  }
  const save = async() => {
    if (actionStore.editData.Name === '') {
      return
    }
    await actionStore.saveAction(actionStore.editData)
  }
  const addActionSub = async() => {
    setActionSubName('')
    const params = {
      Name    : actionSubName,
      ActionId: actionStore.editId
    }
    await actionSubStore.saveActionSub(params).then((res) => {
      if (res.code === 0) {
        messageApi.open({type: 'success', content: '已添加一项子行动'});
      } else {
        messageApi.open({type: 'error', content: res.msg});
      }
    })
  }
  const editActionSubStatus = async(item) => {
    let params = {...item}
    params.Status = params.Status === 0 ? 9 : 0
    await actionSubStore.saveActionSub(params)
  }
  const editActionSubName = async(e, item) => {
    let params = {...item}
    params.Name = e.target.value
    await actionSubStore.saveActionSub(params)
  }
  const delActionSub = async(item) => {
    await actionSubStore.doDel(item).then((res) => {
      if (res.code === 0) {
        messageApi.open({type: 'success', content: '已删除子行动'});
      } else {
        messageApi.open({type: 'error', content: res.msg});
      }
    })
  }

  return (
      <>
        {contextHolder}
        <div className='ploughing l' onClick={() => {
          actionStore.quickAddShowSwitch(false)
        }}>
          <Form
              size='large'
              layout='horizontal'
              style={{marginTop: '25px'}}
              labelCol={{span: 2}}
              form={form}
          >
            <Form.Item>
              <Form.Item name='Name'
                         style={{marginBottom: '1px'}}
                         rules={[
                           {
                             required: true,
                             message : '请填写行动内容!'
                           }
                         ]}>
                <Input style={{backgroundColor: '#f1f3f4'}} bordered={false} onFocus={() => {
                  setNameAnimation(true)
                }} maxLength={100} onBlur={changeName} />
              </Form.Item>
              <span className={nameAnimation ? 'lan-biu lan-biu-show' : 'lan-biu'}></span>
            </Form.Item>
            <Divider />
            <Form.Item>
              <Form.Item colon={false} label={subIcon()} style={{marginBottom: '1px'}}>
                <Input placeholder='子行动是...'
                       maxLength={100}
                       value={actionSubName}
                       defaultValue={actionSubName}
                       onChange={(e) => {
                         setActionSubName(e.target.value)
                       }}
                       bordered={false}
                       onPressEnter={addActionSub}
                       onFocus={() => {
                         setChildAnimation(true)
                       }}
                       onBlur={() => {
                         setChildAnimation(false)
                       }} />
                <span className={childAnimation ? 'lan-biu lan-biu-show' : 'lan-biu'}></span>
              </Form.Item>
              <div className='clearfix'>
                <ul id='subActionList' className='jl-text2'>
                  {actionSubStore.subData.map((sub) => {
                    return (
                        <li key={sub.Id + sub.ActionId} className='sub-action'>
                          <span className='sp-kk' onClick={() => {
                            editActionSubStatus(sub)
                          }}></span>
                          {sub.Status === 9 ? (
                              <p className='subAction text-line-through'>{sub.Name}</p>
                          ) : (
                               <p className='subAction'>
                                 <Input className='subAction-input'
                                        maxLength={100}
                                        defaultValue={sub.Name}
                                        size='small'
                                        bordered={false}
                                        onBlur={(e) => {
                                          editActionSubName(e, sub)
                                        }}>
                                 </Input>
                               </p>
                           )}
                          <span className='jl-gd-right' onClick={() => {
                            delActionSub(sub)
                          }}>
                              <span className='jl-gd-sd-right'></span>
                            </span>
                        </li>
                    )
                  })}
                </ul>
              </div>
            </Form.Item>
            <Form.Item colon={false} label={timeIcon(20)}>
              {setTime ? (
                  <>
                    <Input.Group compact>
                      <Form.Item name='StartTime' style={{width: '110px'}}>
                        <DatePicker allowClear={false} suffixIcon={''} className='input-color' value={actionStore.timeObject.StartTime} onChange={(e) => {
                          changeStartTime(e, 1)
                        }} />
                      </Form.Item>
                      {actionStore.timeObject.DurationTime !== -1 ? (
                          <>
                            <Form.Item name='StartTimeM' style={{width: '70px'}}>
                              <TimePicker allowClear={false} suffixIcon={''} className='input-color' style={{backgroundColor: '#f2f3f4', fontSize: '14px'}} onChange={(e) => {
                                changeStartTime(e, 2)
                              }} value={actionStore.timeObject.StartTimeM} minuteStep={5} format={'HH:mm'} />
                            </Form.Item>
                            {dueTimeShow ? (
                                <>
                                  <Form.Item style={{marginBottom: '5px', paddingRight: '5px'}}>
                                    <span>至</span>
                                  </Form.Item>

                                  {dueTimeDateShow ? (
                                      <Form.Item name='DueTime' style={{width: '110px'}}>
                                        <DatePicker allowClear={false} suffixIcon={''} className='input-color' value={actionStore.timeObject.DueTime} onChange={changeDueTime} />
                                      </Form.Item>
                                  ) : ''}
                                  <Form.Item name='DueTimeM' style={{width: '70px'}}>
                                    <TimePicker allowClear={false} suffixIcon={''}
                                                className='input-color'
                                                style={{backgroundColor: '#f2f3f4', fontSize: '14px'}}
                                                value={actionStore.timeObject.DueTimeM}
                                                onChange={changeDueTime} minuteStep={5} format={'HH:mm'} />
                                  </Form.Item>
                                </>
                            ) : (
                                 <Form.Item style={{marginBottom: '5px', paddingRight: '5px'}}>
                                   <span>开始</span>
                                 </Form.Item>
                             )}
                          </>
                      ) : ''}
                      <Form.Item style={{marginBottom: '5px'}}>
                        <Button type='text' onClick={() => {
                          changeSetTime(false)
                        }}>
                          X
                        </Button>
                      </Form.Item>
                      <br />

                    </Input.Group>
                  </>
              ) : (
                   <Button type='text' size='small' onClick={() => {
                     changeSetTime(true)
                   }}>
                     设置时间
                   </Button>
               )}
            </Form.Item>
            {setTime ? (
                <Form.Item label=' ' colon={false}>
                  <Input.Group compact>
                    {actionStore.timeObject.DurationTime !== -1 ? (
                        <Form.Item style={{width: '130px', marginRight: '100px'}}>
                          <Dropdown
                              trigger={['click']}
                              className='input-color'
                              menu={{items: actionStore.durationTimeOptions, onClick: changeDurationTime}}
                              placement='bottom'
                              arrow={{
                                pointAtCenter: true
                              }}
                          >
                            <Button type='text'>{durationTimeButton}</Button>
                          </Dropdown>
                        </Form.Item>
                    ) : ''}
                    <Form.Item>
                      <Checkbox onChange={setAllDay} checked={actionStore.timeObject.DurationTime === -1}>全天</Checkbox>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
            ) : ''}

            {setTime ? (
                <Form.Item colon={false} label={repeatIcon()}>
                  <Dropdown
                      className='input-color'
                      menu={{items: actionStore.repeatOptions, onClick: changeRepeatOptions}}
                      placement='bottomLeft'
                      trigger={['click']}
                      style={{width: '300px'}}
                      arrow={{
                        pointAtCenter: true
                      }}
                      onFocus={() => {
                        setRepeatAnimation(true)
                      }}
                      onBlur={() => {
                        setRepeatAnimation(false)
                      }}
                  >
                    <Button type='text'>{repeatButton}
                      <span className={repeatAnimation ? 'lan-biu lan-biu-show' : 'lan-biu'}></span>
                    </Button>
                  </Dropdown>
                </Form.Item>
            ) : ''}


            <Form.Item colon={false} label={remindIcon()}>
              <Dropdown
                  className='input-color'
                  menu={{items: actionStore.remindTimeOption, onClick: changeRemindTime}}
                  placement='bottom'
                  trigger={['click']}
                  style={{width: '300px'}}
                  arrow={{
                    pointAtCenter: true
                  }}
                  onFocus={() => {
                    setRemindTimeAnimation(true)
                  }}
                  onBlur={() => {
                    setRemindTimeAnimation(false)
                  }}
              >
                <Button type='text'>{remindTimeButton}
                  <span className={remindTimeAnimation ? 'lan-biu lan-biu-show' : 'lan-biu'}></span>
                </Button>
              </Dropdown>
            </Form.Item>


            <Form.Item colon={false} label={listIcon()}>
              <Dropdown
                  className='input-color'
                  menu={{items: projectStore.menuOptions, onClick: changeProject}}
                  placement='bottom'
                  trigger={['click']}
                  style={{width: '300px'}}
                  arrow={false}
                  onFocus={() => {
                    setProjectAnimation(true)
                  }}
                  onBlur={() => {
                    setProjectAnimation(false)
                  }}
              >
                <Button type='text'>{projectButton}
                  <span className={projectAnimation ? 'lan-biu lan-biu-show' : 'lan-biu'}></span>
                </Button>
              </Dropdown>
            </Form.Item>
            <Form.Item name='ContextId' colon={false} label={markIcon()}>
              <Select
                  size='middle'
                  mode='multiple'
                  allowClear
                  bordered={false}
                  style={{
                    width: '400px'
                  }}
                  placeholder='选择标签'
                  onChange={changeContext}
                  options={contextStore.options}
              />
            </Form.Item>
            <Form.Item name='Description' colon={false} label={descriptionIcon()}>
              <TextArea
                  className='input-color'
                  placeholder='添加备注'
                  onChange={changeDescription}
                  onBlur={save}
                  maxLength={200}
                  autoSize={{
                    minRows: 3,
                    maxRows: 5
                  }}
              />
            </Form.Item>

            <Form.Item colon={false} label={freeIcon()}>
              <Dropdown
                  className='input-color'
                  menu={{items: actionStore.busyOptions, onClick: changeBusy}}
                  placement='bottom'
                  trigger={['click']}
                  style={{width: '300px'}}
                  arrow={false}
                  onFocus={() => {
                    setBusyAnimation(true)
                  }}
                  onBlur={() => {
                    setBusyAnimation(false)
                  }}
              >
                <Button type='text'>{busyButton}
                  <span className={busyAnimation ? 'lan-biu lan-biu-show' : 'lan-biu'}></span>
                </Button>
              </Dropdown>
            </Form.Item>
          </Form>
          {/*<p className='tk-text'>条款&nbsp;-&nbsp;隐私权</p>*/}
        </div>
      </>
  )
})

export {Right}