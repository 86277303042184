import { makeAutoObservable, runInAction } from "mobx";
import { saveAction } from "@utils/api";
import { addAction, addNote, delAction, delNote, getAction, getNote, updateAction, updateNote } from "@utils/db";
import { sort } from "@utils/sort";

class NoteStore {
  allData = [];
  allNote = [];
  editActionInfo = {};
  editNodeInfo = {};
  treeData = [];
  allCount = 0;
  JsonText = "";
  Html = "";
  Description = "";

  saveData = {
    Id: 0,
    ActionId: 0,
    UserId: 0,
    JsonText: "",
    Html: "",
    Markdown: ""
  };

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  updateAllData = async (action = null, note = null) => {
    let noteData = [];
    if (note) {
      noteData = note;
    } else {
      noteData = await getNote().then((res) => {
        return res;
      });
    }
    let data = [];
    if (action) {
      data = action;
    } else {
      data = await getAction().then((res) => {
        return res;
      });
    }
    data = data.filter((item) => {
      return item.Type === 3 && item.UserId === this.rootStore.loginStore.userinfo.UserId;
    });

    runInAction(() => {
      this.allData = sort(data, "UpdateTime");
      this.allNote = noteData;
      this.allCount = this.allData.length;
    });
  };
  editFirstNote = () => {
    if (this.allCount > 0) {
      this.editActionInfo = this.allData[0];
      this.pairEditNote(this.editActionInfo.Id);
    }
  };

  pairEditNote = (id) => {
    let data = this.allNote.find((item) => {
      return item.ActionId === id;
    });
    if (data) {
      this.editNodeInfo = data;
    } else {
      this.editNodeInfo = { ...this.saveData };
      this.editNodeInfo.ActionId = id;
    }
  };

  createNote = async () => {
    let params = { ...this.rootStore.actionStore.saveData };
    params.Op = 1;
    params.UserId = this.rootStore.loginStore.userinfo.UserId;
    params.Type = 3;
    params.JsonText = "";
    params.Html = "";
    params.Markdown = "";
    return await saveAction(params).then((res) => {
      if (res.code === 0) {
        addAction(res.data);
        let note = { ...this.saveData };
        note.Id = res.data.NoteId;
        note.ActionId = res.data.Id;
        addNote(note);
        this.updateAllData().then();
        return { code: 0, msg: "操作成功" };
      } else {
        return { code: 500, msg: res.msg };
      }
    });
  };

  editNote = () => {
    let params = { ...this.editActionInfo };
    params.Op = 2;
    params.UserId = this.rootStore.loginStore.userinfo.UserId;
    params.Type = 3;
    params.JsonText = this.editNodeInfo.JsonText;
    params.Html = this.editNodeInfo.Html;
    params.Markdown = this.editNodeInfo.Markdown;
    return saveAction(params)
      .then((res) => {
        if (res.code === 0) {
          updateAction(this.editActionInfo);
          updateNote(this.editNodeInfo);
          this.updateAllData().then();
          return { code: 0, msg: "操作成功" };
        } else {
          return { code: 500, msg: res.msg };
        }
      })
      .catch((e) => {
        return { code: 500, msg: "操作失败，请稍候重试。" };
      });
  };

  delNote = async (item) => {
    if (item.Id === 0) {
      return { code: 500, msg: "操作失败，请稍候重试。" };
    }
    const params = {
      Id: item.Id,
      UserId: item.UserId,
      Id_Local: item.Id_Local,
      Cycle: item.Cycle,
      Type: 3,
      Op: 3
    };
    delAction(item.Id);
    await this.updateAllData();
    return await saveAction(params)
      .then((res) => {
        if (res.code !== 0) {
          addAction(item);
          this.updateAllData();
        } else {
          delNote(item.Id);
        }
        return res;
      })
      .catch((e) => {
        addAction(item);
        this.updateAllData();
        return { code: 500, msg: "操作失败，请稍候重试。" };
      });
  };

  appSave = (config) => {
    let action = { ...this.editActionInfo };
    action.JsonText = this.editNodeInfo.JsonText ? this.editNodeInfo.JsonText : "";
    action.Html = this.editNodeInfo.Html ? this.editNodeInfo.Html : "";
    action.Op = 2;

    return saveAction(action, "app", config)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return { code: 500, msg: "操作失败，请稍候重试。" };
      });
  };
}

export default NoteStore;
