import Editor from "@/editor";
import { useSearchParams } from "react-router-dom";
import { Decrypt } from "@/utils";
import { useEffect, useState } from "react";
import { Input, message, Modal, Spin } from "antd";
import { getOneNote } from "@utils/api";
import { useStore } from "@/store";

function AppNode() {
  const { noteStore } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const [title, setTitle] = useState("");
  const [config, setConfig] = useState(null);
  const [defaultContent, setDefaultContent] = useState(null);
  const [urlParams] = useSearchParams();

  useEffect(() => {
    let data = urlParams.get("data");
    if (data) {
      data = data.replaceAll("-", "+");
      data = data.replaceAll("_", "/");
      const remainder = data.length % 4;
      if (remainder) {
        data = data + "=".repeat(4 - remainder);
      }
      const decrypt = Decrypt(data, "app");
      if (decrypt) {
        let array = null;
        try {
          array = JSON.parse(decrypt);
        } catch (e) {
          messageApi.error("参数错误");
          return;
        }
        //TODO 根据时间做判断
        // const timestamp = array?.timestamp;
        const params = {
          Id: array?.Id,
          UserId: array?.UserId
        };
        const config = {
          client: array?.clientKey,
          clientNo: array?.clientNo,
          model: array?.phoneModel,
          token: array?.token
        };
        setConfig(config);
        getOneNote(params, "app", config).then((res) => {
          if (res.code === 0) {
            setTitle(res.data.Name);
            noteStore.editNodeInfo = res.data.note;
            delete res.data.note;
            noteStore.editActionInfo = res.data;
            if (noteStore.editNodeInfo.JsonText) {
              try {
                setDefaultContent(JSON.parse(noteStore.editNodeInfo.JsonText));
              } catch (e) {}
            }
            setIsModalOpen(false);
          } else {
            messageApi.error(res.msg);
          }
        });
      } else {
        messageApi.error("参数解析失败");
      }
    }
  }, []);
  const titleChange = (e) => {
    noteStore.editActionInfo.Name = e.currentTarget.value;
    setTitle(e.currentTarget.value);
  };

  const handleContent = (json, html, text) => {
    noteStore.editActionInfo.Description = text.slice(0, 100);
    noteStore.editNodeInfo.JsonText = JSON.stringify(json);
    noteStore.editNodeInfo.Html = html;
    save();
  };

  const save = () => {
    noteStore.appSave(config).then((res) => {
      if (res.code !== 0) {
        messageApi.error(res.msg);
      } else {
        const iosMsg = {
          id: noteStore.editActionInfo.Id,
          title: noteStore.editActionInfo.Name,
          description: noteStore.editActionInfo.Description
        };
        window.webkit.messageHandlers.message.postMessage(JSON.stringify(iosMsg));
      }
    });
  };

  return (
    <div>
      {contextHolder}
      <Modal open={isModalOpen} centered={true} keyboard={false} maskClosable={false} footer={null} width={300} closable={false}>
        <div className="mt-12 h-14">
          <Spin tip="Loading" size="large">
            <div className="" />
          </Spin>
        </div>
      </Modal>
      <div className="mx-16 mt-14">
        <Input placeholder="Untitled" bordered={false} value={title} maxLength={50} size="large" className="text-3xl" onChange={titleChange} onBlur={save} />
      </div>
      <Editor onUpdate={handleContent} defaultContent={defaultContent} />
    </div>
  );
}

export default AppNode;
