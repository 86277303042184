import { makeAutoObservable } from "mobx";
import { getAllData } from "@utils/api";
import {
  addFolder,
  addProject,
  addAction,
  addContext,
  addPerspective,
  addActionSub,
  delAction,
  updateAction,
  updateActionSub,
  delActionSub,
  delProject,
  updateProject,
  delFolder,
  updateFolder,
  delContext,
  updateContext,
  addNote
} from "@utils/db";

import { intToStr } from "@utils/params";
import { getStorage, setStorage } from "@/utils";
import dayjs from "dayjs";

class MainStore {
  treeData = [];
  timer = null;
  timerSync = null;
  timeOnline = null;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  clearIntervalAll() {
    clearInterval(this.timer);
    clearInterval(this.timerSync);
    clearInterval(this.timeOnline);
  }

  getData = async (login = false) => {
    if (login === false) {
      const lastTime = getStorage("updateTime");
      if (lastTime && dayjs().unix() - lastTime < 600) {
        this.rootStore.actionStore.updateAllData();
        this.rootStore.contextStore.updateAllData();
        this.rootStore.actionSubStore.updateAllData();
        this.rootStore.folderStore.updateAllData();
        this.rootStore.projectStore.updateAllData();
        this.rootStore.noteStore.updateAllData();
        return;
      }
    }
    setStorage("updateTime", dayjs().unix());
    let params = { UserId: this.rootStore.loginStore.userinfo.UserId, SyncFlag: 1 };
    return await getAllData(params)
      .then((res) => {
        // console.log(res);
        addFolder(res.data.Folder);
        addProject(res.data.Project);
        addAction(res.data.Action);
        addContext(res.data.Context);
        addPerspective(res.data.Perspective);
        addActionSub(res.data.ActionSub);
        addNote(res.data.NoteData);
        this.rootStore.actionStore.updateAllData(res.data.Action);
        this.rootStore.contextStore.updateAllData(res.data.Context);
        this.rootStore.actionSubStore.updateAllData(res.data.ActionSub);
        this.rootStore.folderStore.updateAllData(res.data.Folder);
        this.rootStore.projectStore.updateAllData(res.data.Project);
        this.rootStore.noteStore.updateAllData(res.data.Action, res.data.NoteData);
        return res;
      })
      .catch((error) => {
        console.log(error);
        return { code: 500, msg: "用户数据获取失败,请稍候重试" };
      });
  };

  getSyncData = async () => {
    const params = { UserId: this.rootStore.loginStore.userinfo.UserId, SyncFlag: 0 };
    return await getAllData(params)
      .then((res) => {
        if (res && res.code === 0) {
          const logs = res.data.Log;
          if (logs.length > 0) {
            logs.forEach((item) => {
              const data = JSON.parse(item.sync_data);
              if (!Array.isArray(data) && data.hasOwnProperty("table")) {
                switch (data.table) {
                  case "wegoal_action":
                    this.handleSyncAction(data);
                    break;
                  case "wegoal_project":
                    this.handleSyncProject(data);
                    break;
                  case "wegoal_folder":
                    this.handleSyncFolder(data);
                    break;
                  case "wegoal_context":
                    this.handleSyncContext(data);
                    break;
                  default:
                    return;
                }
              }
            });
          }
        }
      })
      .catch((error) => {
        return { code: 500, msg: "用户数据获取失败,请稍候重试" };
      });
  };

  handleWebsocketData = (data) => {
    if (!Array.isArray(data) && data.hasOwnProperty("table")) {
      switch (data.table) {
        case "wegoal_action":
          this.handleSyncAction(data);
          break;
        case "wegoal_project":
          this.handleSyncProject(data);
          break;
        case "wegoal_folder":
          this.handleSyncFolder(data);
          break;
        case "wegoal_context":
          this.handleSyncContext(data);
          break;
        default:
          return;
      }
    }
  };

  handleSyncAction(data) {
    const arr = intToStr(data.data);
    switch (data.Op) {
      case 1:
        addAction(arr);
        break;
      case 3:
        delAction(arr.Id);
        break;
      case 22:
        addActionSub(arr);
        break;
      case 23:
        addActionSub(arr);
        break;
      case 24:
        delActionSub(arr.Id);
        break;
      case 98:
        if (arr.table === "wegoal_action_sub") {
          addActionSub(arr.data);
        } else {
          addAction(arr.data);
        }
        break;
      case 99:
        arr.data.forEach((one) => {
          if (one.table === "wegoal_action") {
            if (one.Op === "3") {
              delAction(one.Id);
            } else {
              updateAction(one);
            }
          }
          if (one.table === "wegoal_action_sub") {
            if (one.Op === "3") {
              delActionSub(one.Id);
            } else {
              updateActionSub(one);
            }
          }
        });
        break;
      default:
        addAction(arr);
    }
    this.rootStore.actionStore.updateAllData();
    this.rootStore.actionSubStore.updateAllData().then(() => {
      this.rootStore.actionSubStore.getSub(this.rootStore.actionStore.editId);
    });
  }

  handleSyncProject(data) {
    const arr = intToStr(data.data);
    switch (data.Op) {
      case 1:
        addProject(arr);
        break;
      case 3:
        delProject(arr.Id);
        break;
      default:
        updateProject(arr);
    }
    this.rootStore.projectStore.updateAllData();
  }

  handleSyncFolder(data) {
    const arr = intToStr(data.data);
    switch (data.Op) {
      case 1:
        addFolder(arr);
        break;
      case 3:
        delFolder(arr.Id);
        break;
      default:
        updateFolder(arr);
    }
    this.rootStore.folderStore.updateAllData();
  }

  handleSyncContext(data) {
    const arr = intToStr(data.data);
    switch (data.Op) {
      case 1:
        addContext(arr);
        break;
      case 3:
        delContext(arr.Id);
        break;
      default:
        updateContext(arr);
    }
    this.rootStore.contextStore.updateAllData();
  }
}

export default MainStore;
