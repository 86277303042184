import { BubbleMenu, isNodeSelection } from "@tiptap/react";
import { useState } from "react";
import { BoldIcon, ItalicIcon, UnderlineIcon, StrikethroughIcon, CodeIcon } from "lucide-react";
import { NodeModel } from "./nodeModel";
import { ColorModel } from "./colorModel";
import { LinkModel } from "./linkModel";
import { clsxMerge } from "../utils";

export const EditorBubbleMenu = (props) => {
  const items = [
    {
      name: "bold",
      isActive: () => props.editor.isActive("bold"),
      command: () => props.editor.chain().focus().toggleBold().run(),
      icon: BoldIcon
    },
    {
      name: "italic",
      isActive: () => props.editor.isActive("italic"),
      command: () => props.editor.chain().focus().toggleItalic().run(),
      icon: ItalicIcon
    },
    {
      name: "underline",
      isActive: () => props.editor.isActive("underline"),
      command: () => props.editor.chain().focus().toggleUnderline().run(),
      icon: UnderlineIcon
    },
    {
      name: "strike",
      isActive: () => props.editor.isActive("strike"),
      command: () => props.editor.chain().focus().toggleStrike().run(),
      icon: StrikethroughIcon
    },
    {
      name: "code",
      isActive: () => props.editor.isActive("code"),
      command: () => props.editor.chain().focus().toggleCode().run(),
      icon: CodeIcon
    }
  ];

  const bubbleMenuProps = {
    ...props,
    shouldShow: ({ state, editor }) => {
      const { selection } = state;
      const { empty } = selection;
      return !(editor.isActive("image") || empty || isNodeSelection(selection));
    },
    tippyOptions: {
      moveTransition: "transform 0.15s ease-out",
      onHidden: () => {
        setIsNodeModelOpen(false);
        setIsColorModelOpen(false);
        setIsLinkModelOpen(false);
      }
    }
  };

  const [isNodeModelOpen, setIsNodeModelOpen] = useState(false);
  const [isColorModelOpen, setIsColorModelOpen] = useState(false);
  const [isLinkModelOpen, setIsLinkModelOpen] = useState(false);

  return (
    <BubbleMenu {...bubbleMenuProps} className="flex w-fit divide-x divide-stone-200 rounded border border-stone-200 bg-white shadow-xl">
      <NodeModel
        editor={props.editor}
        isOpen={isNodeModelOpen}
        setIsOpen={() => {
          setIsNodeModelOpen(!isNodeModelOpen);
          setIsColorModelOpen(false);
          setIsLinkModelOpen(false);
        }}
      />
      <div className="flex">
        {items.map((item, index) => (
          <button key={index} onClick={item.command} className="p-2 text-stone-600 hover:bg-stone-100 active:bg-stone-200" type="button">
            <item.icon
              className={clsxMerge("h-4 w-4", {
                "text-blue-500": item.isActive()
              })}
            />
          </button>
        ))}
      </div>
      <LinkModel
        editor={props.editor}
        isOpen={isLinkModelOpen}
        setIsOpen={() => {
          setIsLinkModelOpen(!isLinkModelOpen);
          setIsColorModelOpen(false);
          setIsNodeModelOpen(false);
        }}
      />
      <ColorModel
        editor={props.editor}
        isOpen={isColorModelOpen}
        setIsOpen={() => {
          setIsColorModelOpen(!isColorModelOpen);
          setIsNodeModelOpen(false);
          setIsLinkModelOpen(false);
        }}
      />
    </BubbleMenu>
  );
};
