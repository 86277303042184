import StarterKit from "@tiptap/starter-kit";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import { Color } from "@tiptap/extension-color";
import DragAndDrop from "@/editor/extnsions/dragAndDrop";
import { mergeAttributes } from "@tiptap/core";
import slashCommands from "@/editor/extnsions/slashCommands";

export const defaultExtensions = [
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3],
      HTMLAttributes: {
        class: "my-0"
      }
    },
    bulletList: {
      HTMLAttributes: {
        class: "list-disc list-outside leading-3 my-0"
      }
    },
    orderedList: {
      HTMLAttributes: {
        class: "list-decimal list-outside leading-3 my-0"
      }
    },
    listItem: {
      HTMLAttributes: {
        class: "leading-normal my-0"
      }
    },
    blockquote: {
      HTMLAttributes: {
        class: "border-l-4 border-stone-700 my-1"
      }
    },
    codeBlock: {
      HTMLAttributes: {
        class: "rounded-sm bg-stone-100 p-3 font-mono font-medium text-stone-800 my-1"
      }
    },
    code: {
      HTMLAttributes: {
        class: "rounded-md bg-stone-200 px-1.5 py-1 font-mono font-medium text-stone-900",
        spellcheck: "false"
      }
    },
    dropcursor: {
      color: "#DBEAFE",
      width: 4
    },
    gapcursor: false
  }),
  HorizontalRule.extend({
    renderHTML({ HTMLAttributes }) {
      return ["div", ["hr", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]];
    }
  }).configure({
    HTMLAttributes: {
      class: "my-4 border-t border-stone-400"
    }
  }),
  Link.configure({
    HTMLAttributes: {
      class: "text-lg underline underline-offset-[3px] text-stone-500 hover:text-stone-600 cursor-pointer transition-colors"
    }
  }),
  Placeholder.configure({
    placeholder: ({ node }) => {
      if (node.type.name === "heading") {
        return `Heading ${node.attrs.level}`;
      }
      if (node.type.name === "codeBlock") {
        return "Press Ctrl/Command + Enter to leave the fenced code block";
      }
      return "Press ‘/’ for commands…";
    },
    includeChildren: true
  }),
  Highlight.configure({
    multicolor: true
  }),
  TaskList.configure({
    HTMLAttributes: {
      class: "not-prose pl-1"
    }
  }),
  TaskItem.configure({
    HTMLAttributes: {
      class: "flex items-start my-1"
    },
    nested: true
  }),
  TextStyle,
  Color,
  Underline,
  slashCommands,
  DragAndDrop
];
