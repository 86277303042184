import { observer } from "mobx-react-lite";
import { useStore } from "@/store";
import { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Popconfirm, Popover, Space, Tree } from "antd";
import { reaction, runInAction } from "mobx";
import { getOneContext } from "@utils/db";

const MainLeftContext = observer(function () {
  const { contextStore, actionStore } = useStore();
  const [formContext] = Form.useForm();
  const [treeData, setTreeData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    reaction(
      () => contextStore.treeData,
      () => {
        setTreeData(handleTree(contextStore.treeData));
      },
      { fireImmediately: true }
    );
  }, []);

  const updateAllData = async () => {
    await contextStore.updateAllData();
  };
  const onClick = (selectedKeys, info) => {
    runInAction(() => {
      actionStore.editId = null;
      actionStore.editData = null;
      actionStore.groupType = "context";
      actionStore.groupOtherId = info.key;
      actionStore.tableTitle = info.title;
    });
  };
  const handleTree = (arr) => {
    return arr.map((item) => {
      let json = { ...item };
      json.title = handleTreeTitle(item);
      json.children = item.children ? handleTree(item.children) : [];
      return json;
    });
  };

  const handleTreeTitle = (item) => {
    const content = (
      <div>
        <p
          className="sandyx-menu"
          onClick={() => {
            openEditContext(item);
          }}
        >
          编辑
        </p>
        <Popconfirm
          title="删除"
          description="您确认要删除吗?"
          okText="删除"
          cancelText="取消"
          okButtonProps={{
            className: "bg-emerald-500"
          }}
          onConfirm={() => {
            delContext(item);
          }}
        >
          <p className="sandyx-menu">删除</p>
        </Popconfirm>
      </div>
    );
    return (
      <>
        <span>{item.title}</span>
        <span className="zc-qb-gd r">
          <Popover content={content} trigger="hover" placement="rightTop">
            <span className="zc-qb-gd-tu"></span>
          </Popover>
        </span>
      </>
    );
  };

  const onDragEnter = () => {};

  const openAddContext = () => {
    contextStore.setModelText("新增标签");
    formContext.setFieldsValue({
      Id: null,
      ContextName: ""
    });
    contextStore.switchModel();
  };

  const delContext = async (item) => {
    await contextStore.delContext(item.key).then();
    await updateAllData();
  };

  const modelCancel = () => {
    contextStore.switchModel();
    contextStore.setContextName("");
    formContext.resetFields();
  };

  const formSubmit = async (values) => {
    contextStore.switchModel();
    contextStore.setContextName("");
    await contextStore.saveContext(values).then((res) => {
      if (res.code === 0) {
        updateAllData();
      } else {
        messageApi.open({ type: "error", content: res.msg });
      }
    });
  };

  const openEditContext = async (item) => {
    contextStore.setModelText("修改标签");
    await getOneContext(item.key).then((res) => {
      formContext.setFieldsValue({
        Id: res.Id,
        ContextName: res.ContextName
      });
      contextStore.switchModel();
    });
  };

  return (
    <>
      {contextHolder}
      <Tree
        blockNode
        showIcon
        // draggable
        switcherIcon={() => null}
        selectable={false}
        onClick={onClick}
        treeData={treeData}
        onDragEnter={onDragEnter}
        onDragEnd={onDragEnter}
      />

      <div className="xj-fz">
        <img alt="+" src={require("../assets/images/xj-fz.jpg")} />
        <p className="zc-text l" onClick={openAddContext}>
          新增标签
        </p>
      </div>
      <Modal open={contextStore.showModel} centered={true} onCancel={modelCancel} footer={null} forceRender title={contextStore.modelText}>
        <Form size="large" layout="horizontal" style={{ marginTop: "25px" }} labelCol={{ span: 2 }} onFinish={formSubmit} form={formContext}>
          <Form.Item
            name="ContextName"
            rules={[
              {
                required: true,
                message: "请填写文件夹名称!"
              }
            ]}
          >
            <Input placeholder="标签名称" />
          </Form.Item>
          <Form.Item name="Id" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button onClick={modelCancel}>取消</Button>
              <Button type="primary" htmlType="submit" className="bg-emerald-500">
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});

export { MainLeftContext };
