import { observer } from "mobx-react-lite";
import "@css/main.scss";
import "@css/andt.scss";
import { MainLeftGroup, MainLeftHead, MainLeftMenu, Middle, Right, RightEmpty } from "@/components";
import { useEffect } from "react";
import { useStore } from "@/store";
import { removeStorage } from "@/utils";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import MiddleNote from "@components/middleNote";
import RightNote from "@components/rightNote";

window.Pusher = require("pusher-js");

function Main() {
  const { mainStore, loginStore, actionStore } = useStore();
  const userinfo = loginStore.userinfo;
  const clientType = "h5";

  const myPusherConfig = {
    key: "local",
    wsHost: "ws.my",
    wsPort: 6001,
    cluster: "mt1",
    forceTLS: false,
    broadcaster: "pusher",
    disableStats: true,
    enabledTransports: ["ws"],
    disabledTransports: ["wss"],
    channelAuthorization: {
      endpoint: "http://gengyun.my/api/channel-test-auth",
      transport: "ajax",
      params: {
        UserId: loginStore.userinfo.UserId,
        token: loginStore.userinfo.token,
        clientType: clientType
      },
      headers: {},
      customHandler: null
    }
  };

  const pusherConfig = {
    key: "local",
    wsHost: "wss.wegoal.cc",
    wsPort: 6001,
    cluster: "mt1",
    forceTLS: false,
    broadcaster: "pusher",
    disableStats: true,
    enabledTransports: ["ws"],
    disabledTransports: ["wss"],
    channelAuthorization: {
      endpoint: "https://api.wegoal.cc/api/channel-test-auth",
      transport: "ajax",
      params: {
        UserId: loginStore.userinfo.UserId,
        token: loginStore.userinfo.token,
        clientType: clientType
      },
      headers: {},
      customHandler: null
    }
  };
  window.Echo = new Echo({
    client: new Pusher(pusherConfig.key, pusherConfig),
    broadcaster: pusherConfig.broadcaster
  });
  const stayOnline = () => {
    window.Echo.connector.pusher.send_event("IamOnline", {
      UserId: loginStore.userinfo.UserId,
      token: loginStore.userinfo.token,
      clientType: clientType
    });
  };
  useEffect(() => {
    mainStore.clearIntervalAll();
    if (!userinfo || userinfo["token"] === "") {
      mainStore.clearIntervalAll();
      removeStorage("userinfo");
      window.location.href = window.location.origin + "/login";
    }

    mainStore.getData();
    window.Echo.private("SyncLog-" + clientType + "-" + loginStore.userinfo.UserId).listen("SyncLog", (e) => {
      mainStore.handleWebsocketData(e);
    });
    stayOnline();
    mainStore.timeOnline = setInterval(function () {
      stayOnline();
    }, 30000);
    return () => {
      mainStore.clearIntervalAll();
    };
  }, []);

  return (
    <div className="index">
      <div className="zc-dh l">
        <MainLeftHead />
        <div
          className="gdt123 content mCustomScrollbar"
          onClick={() => {
            actionStore.quickAddShowSwitch(false);
          }}
        >
          <MainLeftMenu />
          <MainLeftGroup />
          <ul className="zc-zn">
            <li>
              <p className="zc-text">将来某天</p>
            </li>
          </ul>
        </div>

        <p className="tk-text">条款&nbsp;-&nbsp;隐私权</p>
      </div>
      <div className="y-er">
        {actionStore.groupType === "note" ? (
          <>
            <MiddleNote />
            <RightNote />
          </>
        ) : (
          <>
            <Middle /> {actionStore.editId !== null ? <Right /> : <RightEmpty />}
          </>
        )}
      </div>
    </div>
  );
}

export default observer(Main);
