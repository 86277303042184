import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function clsxMerge(...inputs) {
  return twMerge(clsx(inputs));
}

export function isObject(value) {
  return Object.prototype.toString.call(value) === "[object Object]";
}
