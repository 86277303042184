import axios from "axios";
import { removeStorage } from "@utils/storage";
import { clearAll } from "@utils/db";

const exceptionPath = ["/login", "/app_note", "/register"];

const http = axios.create({
  baseURL: "https://api.wegoal.cc/",
  // baseURL: "http://gengyun.my/",

  timeout: 30000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  }
});

http.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    if ((response.data.code === 404 || response.data.code === 403) && exceptionPath.indexOf(window.location.pathname) === -1) {
      removeStorage("userinfo");
      clearAll();
      window.location.href = window.location.origin + "/login";
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { http };
