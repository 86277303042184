import { EditorContent, useEditor } from "@tiptap/react";
import { useDebouncedCallback } from "use-debounce";
import { defaultExtensions } from "./extnsions";
import { EditorBubbleMenu } from "./bubbleMenu";

import "./css/index.css";
import { useEffect } from "react";

const defaultEditorProps = {
  attributes: {
    class: `prose-lg prose-stone dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full`
  }
};

export default function Editor(props) {
  const className = props.hasOwnProperty("className") ? props.className : "";

  const debouncedUpdates = useDebouncedCallback(async ({ editor }) => {
    const json = editor.getJSON();
    const html = editor.getHTML();
    const text = editor.getText({
      blockSeparator: " "
    });
    props.onUpdate(json, html, text);
  }, 1000);

  const editor = useEditor({
    extensions: [...defaultExtensions],
    editorProps: {
      ...defaultEditorProps
    },
    onUpdate: (e) => {
      debouncedUpdates(e);
    },
    autofocus: "end"
  });
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === "Enter") {
        editor.chain().focus().unsetBold().unsetItalic().unsetColor().unsetHighlight().unsetUnderline().unsetStrike().unsetCode().run();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [editor]);
  useEffect(() => {
    if (!editor) return;
    editor.commands.setContent(props.defaultContent);
  }, [editor, props]);

  return (
    <div
      onClick={() => {
        editor?.chain().focus().run();
      }}
      className={className}
    >
      {editor && <EditorBubbleMenu editor={editor} />}
      <EditorContent editor={editor} />
    </div>
  );
}
