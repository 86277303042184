import { randomStr } from "@utils/random";
import { getStorage } from "@utils/storage";
import dayjs from "dayjs";

const clientKey = "AeQtk3BYvRxa8bCNbOGNsJEqIoOViDsi";
const securityKey = "0lu1@(d-f$zyNdlb[y4N";
/**
 * 格式化请求参数
 * @param data
 * @param url
 * @param config {client:String,clientNo:String,model:String,token:String}
 * @returns {module:url.URLSearchParams}
 */
const formatData = (data, url, config = null) => {
  let client = config?.hasOwnProperty("client") ? config.client : clientKey;
  let clientNo = config?.hasOwnProperty("clientNo") ? config.clientNo : "h5";
  let model = config?.hasOwnProperty("model") ? config.model : "h5";
  let token = "gengyun";
  if (config?.hasOwnProperty("token")) {
    token = config.token;
  } else {
    const userinfo = getStorage("userinfo");
    if (userinfo && userinfo["token"] !== undefined) {
      token = userinfo["token"];
    }
  }
  const timestamp = dayjs().unix();
  const nonce = randomStr();
  const string = nonce + securityKey + token + url + timestamp + client + data + clientNo;
  const md5 = require("md5");
  const sign = md5(string);

  const params = new URLSearchParams();
  params.append("clientKey", client);
  params.append("timestrap", timestamp.toString());
  params.append("data", data);
  params.append("phoneModel", model);
  params.append("clientNo", clientNo);
  params.append("token", token);
  params.append("nonce", nonce);
  params.append("sign", sign);

  return params;
};

const intToStr = (data) => {
  return data;
};

export { formatData, intToStr };
